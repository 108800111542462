import { computed } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const useFabricator = () => {
  const { plugins, store, router } = useGlobalHooks()

  const isFabricatorRoute = computed(() => {
    const { path, params } = router.currentRoute.value

    return [
      '/fabricator-list',
      `/fabricator/${params.id}`,
      `/fabricator/${params.id}/quick-generate`,
      `/fabricator/${params.id}/generate-swatches`,
      `/fabricator/${params.id}/generate-swatches-with-seed`,
      `/fabricator/${params.id}/upload-swatch`,
      `/fabricator/${params.id}/make-seamless`,
      `/fabricator/${params.id}/upscale-texture`,
      `/fabricator/${params.id}/generate-pbr-maps`,
    ].includes(path)
  })

  const checkTextureGenerationStatus = () => {
    const { status, customerId } = store.getters.fabricatorTextureGeneration || {}

    if (!status) return false

    if (status === 'final' || store.getters.me.id !== customerId) return true

    return false
  }

  const isViewMode = computed(() => {
    const { status, customerId } = store.getters.fabricatorTextureGeneration || {}

    if (!customerId) return false

    if (status === 'final' || !isGeneratedByMe.value) return true

    return false
  })

  const isGeneratedByMe = computed(() => {
    const { id: currentId } = store.getters.me || {}
    const { customerId } = store.getters.fabricatorTextureGeneration || {}

    return currentId === customerId
  })

  const refineResolutionText = (resolution) => {
    if (resolution === 1024) return '1K'

    if (resolution === 2048) return '2K'

    if (resolution === 4096) return '4K'

    return ''
  }

  const parsePurePrompt = (text = '') => {
    const tokens = parsePromptTokens(text)

    const pureCharacters = []

    String(text)
      .split('')
      .forEach((char, index) => {
        const targetToken = tokens.find(
          ({ startIndex: tokenStartIndex, endIndex: tokenEndIndex }) =>
            index >= tokenStartIndex && index < tokenEndIndex,
        )

        if (!targetToken) {
          pureCharacters.push(char)
          return
        }

        if (
          index === targetToken.startIndex ||
          (index < targetToken.endIndex && index >= targetToken.endIndex - 1 - Math.abs(targetToken.weight))
        ) {
          pureCharacters.push('')
          return
        }

        pureCharacters.push(char)
      })

    return pureCharacters.join('')
  }

  const parsePromptTokens = (text = '') => {
    const tokens = []

    let startIndex = -1

    String(text)
      .split('')
      .forEach((char, index) => {
        if (char === '(') {
          startIndex = index
          return
        }

        if (startIndex === -1) return

        let tokenLength = 0

        if ([')+', ')-'].includes(text.substring(index, index + 2))) {
          tokenLength = 2
        }

        if ([')++', ')--'].includes(text.substring(index, index + 3))) {
          tokenLength = 3
        }

        if ([')+++', ')---'].includes(text.substring(index, index + 4))) {
          tokenLength = 4
        }

        if (tokenLength === 0) return

        const weightedText = text.substring(startIndex, index + tokenLength)
        const pureText = weightedText.substring(1, weightedText.length - tokenLength)
        const weight = (weightedText.slice(-1) === '+' ? 1 : -1) * (tokenLength - 1)

        tokens.push({
          text: weightedText,
          pureText,
          startIndex,
          endIndex: index + tokenLength,
          weight,
        })
        startIndex = -1
      })

    return tokens
  }

  const parsePureNegativePrompt = (text = '') => {
    const tokens = parseNegativePromptTokens(text)

    const pureCharacters = []

    String(text)
      .split('')
      .forEach((char, index) => {
        const targetToken = tokens.find(
          ({ startIndex: tokenStartIndex, endIndex: tokenEndIndex }) =>
            index >= tokenStartIndex && index < tokenEndIndex,
        )

        if (!targetToken) {
          pureCharacters.push(char)
          return
        }

        if (
          index === targetToken.startIndex ||
          (index < targetToken.endIndex && index >= targetToken.endIndex - 1 - Math.abs(targetToken.weight))
        ) {
          pureCharacters.push('')
          return
        }

        pureCharacters.push(char)
      })

    return pureCharacters.join('')
  }

  const parseNegativePromptTokens = (text = '') => {
    const tokens = []

    let startIndex = -1

    String(text)
      .split('')
      .forEach((char, index) => {
        if (char === '(') {
          startIndex = index
          return
        }

        if (startIndex === -1) return

        let tokenLength = 0

        if ([')+'].includes(text.substring(index, index + 2))) {
          tokenLength = 2
        }

        if ([')++'].includes(text.substring(index, index + 3))) {
          tokenLength = 3
        }

        if ([')+++'].includes(text.substring(index, index + 4))) {
          tokenLength = 4
        }

        if ([')++++'].includes(text.substring(index, index + 5))) {
          tokenLength = 5
        }

        if ([')+++++'].includes(text.substring(index, index + 6))) {
          tokenLength = 6
        }

        if (tokenLength === 0) return

        const weightedText = text.substring(startIndex, index + tokenLength)
        const pureText = weightedText.substring(1, weightedText.length - tokenLength)

        tokens.push({
          text: weightedText,
          pureText,
          startIndex,
          endIndex: index + tokenLength,
          weight: tokenLength - 1,
        })
        startIndex = -1
      })

    return tokens
  }

  const getFabricType = (fabricTypeId) => {
    if (!store.getters.fabricatorFilters) return {}

    return store.getters.fabricatorFilters.category.filters.find(({ id }) => id === fabricTypeId)
  }

  const initFabricatorAvailableLeftTabs = () => {
    const { fabricatorCurrentLeftTab } = store.getters

    if (['generate-swatches', 'upload-swatch'].includes(fabricatorCurrentLeftTab)) {
      store.commit('setFabricatorAvailableLeftTabs', ['generate-swatches'])
      return
    }

    if (fabricatorCurrentLeftTab === 'make-seamless') {
      store.commit('setFabricatorAvailableLeftTabs', ['generate-swatches', 'make-seamless'])
      return
    }

    if (fabricatorCurrentLeftTab === 'upscale-texture') {
      store.commit('setFabricatorAvailableLeftTabs', ['generate-swatches', 'make-seamless', 'upscale-texture'])
      return
    }

    if (fabricatorCurrentLeftTab === 'generate-pbr-maps') {
      store.commit('setFabricatorAvailableLeftTabs', [
        'generate-swatches',
        'make-seamless',
        'upscale-texture',
        'generate-pbr-maps',
      ])
    }
  }

  const currentVersionHistory = computed(() => {
    const { fabricatorTextureGeneration, fabricatorCurrentVersionHistories } = store.getters

    if (!fabricatorTextureGeneration || !fabricatorCurrentVersionHistories) return {}

    return Object.values(fabricatorCurrentVersionHistories)
      .filter((data) => data)
      .find(({ id } = {}) => id === fabricatorTextureGeneration.currentVersionHistoryId)
  })

  const refineTab = (str) => {
    const kebab = refineStep(str, 'kebab')

    if (['select-swatch', 'swatch-copied'].includes(kebab)) return 'make-seamless'

    return kebab
  }

  const refineStep = (str, format) => {
    const splited = (() => {
      if (str.includes('_')) return str.split('_')

      if (str.includes('-')) return str.split('-')

      return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').split('-')
    })()

    if (format === 'camel') {
      return splited
        .map((item, index) => {
          if (index === 0) return item

          return item.charAt(0).toUpperCase() + item.slice(1)
        })
        .join('')
    }

    if (format === 'snake') {
      return splited.join('_')
    }

    if (format === 'kebab') {
      return splited.join('-')
    }

    return ''
  }

  const viewingVersionHistory = computed(() => {
    const { fabricatorCurrentLeftTab, fabricatorCurrentVersionHistories } = store.getters

    if (!fabricatorCurrentLeftTab || !fabricatorCurrentVersionHistories) return {}

    const { makeSeamless, selectSwatch, swatchCopied } = fabricatorCurrentVersionHistories

    if (fabricatorCurrentLeftTab === 'make-seamless' && !makeSeamless && swatchCopied) return swatchCopied

    if (fabricatorCurrentLeftTab === 'make-seamless' && !makeSeamless && selectSwatch) return selectSwatch

    return fabricatorCurrentVersionHistories[refineStep(fabricatorCurrentLeftTab, 'camel')]
  })

  const getHistoryDetailImages = (key, { images } = {}) => {
    if (!images) return []

    return images.filter(({ category }) => category === key)
  }

  const getHistoryDetailImage = (key, { images } = {}) => {
    if (!images) return {}

    return images.find(({ category }) => category === key)
  }

  const contentImage = computed(() => {
    const { fabricatorCurrentLeftTab, fabricatorCurrentVersionHistories } = store.getters
    const { selectSwatch, makeSeamless, upscaleTexture, generatePbrMaps, quickGenerate, swatchCopied } =
      fabricatorCurrentVersionHistories || {}

    if (
      !['make-seamless', 'upscale-texture', 'generate-pbr-maps', 'quick-generate'].includes(fabricatorCurrentLeftTab)
    ) {
      return {}
    }

    if (fabricatorCurrentLeftTab === 'make-seamless' && makeSeamless) {
      return getHistoryDetailImage(makeSeamless.status === 'completed' ? 'fabric' : 'input', makeSeamless)
    }

    if (fabricatorCurrentLeftTab === 'make-seamless' && selectSwatch) {
      return getHistoryDetailImage('swatch', selectSwatch)
    }

    if (fabricatorCurrentLeftTab === 'make-seamless' && swatchCopied) {
      return getHistoryDetailImage('swatch', swatchCopied)
    }

    if (fabricatorCurrentLeftTab === 'upscale-texture' && upscaleTexture) {
      return getHistoryDetailImage(upscaleTexture.status === 'completed' ? 'fabric' : 'input', upscaleTexture)
    }

    if (fabricatorCurrentLeftTab === 'upscale-texture' && makeSeamless) {
      return getHistoryDetailImage('fabric', makeSeamless)
    }

    if (fabricatorCurrentLeftTab === 'upscale-texture' && swatchCopied) {
      return getHistoryDetailImage('swatch', swatchCopied)
    }

    if (fabricatorCurrentLeftTab === 'quick-generate' && quickGenerate) {
      return store.getters.fabricatorViewModeImage
    }

    if (fabricatorCurrentLeftTab === 'generate-pbr-maps' && generatePbrMaps && generatePbrMaps.status === 'completed') {
      return store.getters.fabricatorViewModeImage
    }

    if (fabricatorCurrentLeftTab === 'generate-pbr-maps' && upscaleTexture) {
      return getHistoryDetailImage('fabric', upscaleTexture)
    }

    if (fabricatorCurrentLeftTab === 'generate-pbr-maps' && makeSeamless) {
      return getHistoryDetailImage('fabric', makeSeamless)
    }

    if (fabricatorCurrentLeftTab === 'generate-pbr-maps' && swatchCopied) {
      return getHistoryDetailImage('swatch', swatchCopied)
    }

    return getHistoryDetailImage('swatch', selectSwatch)
  })

  const contentButtonType = computed(() => {
    const { fabricatorCurrentLeftTab, fabricatorImageCroppedOptions } = store.getters
    const { step, status } = viewingVersionHistory.value || {}

    if (
      fabricatorCurrentLeftTab === 'make-seamless' &&
      step === 'make_seamless' &&
      status === 'completed' &&
      !isViewMode.value &&
      !fabricatorImageCroppedOptions
    ) {
      return 'make-seamless-next'
    }

    if (
      fabricatorCurrentLeftTab === 'upscale-texture' &&
      step === 'upscale_texture' &&
      status === 'completed' &&
      !isViewMode.value
    ) {
      return 'upscale-texture-next'
    }

    return ''
  })

  const onClickContentButton = () => {
    if (checkTextureGenerationStatus() || !contentButtonType.value) return

    store.commit('setFabricatorCurrentRightTab', '')

    if (contentButtonType.value === 'make-seamless-next') {
      store.commit('setFabricatorCurrentLeftTab', 'upscale-texture')
      store.commit('setFabricatorAvailableLeftTabs', ['generate-swatches', 'make-seamless', 'upscale-texture'])
      return
    }

    if (contentButtonType.value === 'upscale-texture-next') {
      store.commit('setFabricatorCurrentLeftTab', 'generate-pbr-maps')
      store.commit('setFabricatorAvailableLeftTabs', [
        'generate-swatches',
        'make-seamless',
        'upscale-texture',
        'generate-pbr-maps',
      ])
      store.commit('setFabricatorContentsType', '3d-viewer')
    }
  }

  const getIdAndStatus = (targetStep, values = []) =>
    values.filter((v) => v).find(({ step }) => step === targetStep) || {}

  const refineAdjustWeight = (weight = 0) =>
    [
      { key: -2, text: 'Minimal' },
      { key: -1, text: 'Low' },
      { key: 0, text: 'Moderate' },
      { key: 1, text: 'High' },
      { key: 2, text: 'Maximal' },
    ].find(({ key }) => {
      if (typeof weight === 'object') return key === 0

      return key === parseInt(weight, 10)
    })?.text || ''

  const showErrorModal = (type = 'non-paid', cost = 100) => {
    store.commit('setLoading', { isShow: false })

    if (type === 'overlap') {
      plugins.$modal.basicV2({
        titleIcon: 'error-v2',
        body: 'Wait for your ongoing job to finish before starting a new one.<br/><a href="/fabricator-list" target="_blank" style="color: var(--cyan-tx-100); font-weight: 500; text-transform:uppercase;">Check Generation List</a>',
        buttons: [{ buttonText: 'OK' }],
      })
      return
    }

    const isPaid = type === 'paid'

    plugins.$modal
      .basicV2({
        titleIcon: 'error-v2',
        body: `Oops! Something went wrong.${isPaid ? ' ' : '<br/>'}Please try again.${
          isPaid ? '<br/>Credits have been restored.' : ''
        }`,
        buttons: [{ buttonText: 'OK' }],
      })
      .then(() => {
        if (!isPaid) return

        plugins.$toast.custom({
          type: 'coin',
          colorTheme: 'green',
          html: '<b>Credits have been restored!</b>',
        })
      })
  }

  return {
    isFabricatorRoute,
    checkTextureGenerationStatus,
    isViewMode,
    isGeneratedByMe,
    refineResolutionText,
    parsePurePrompt,
    parsePromptTokens,
    parsePureNegativePrompt,
    parseNegativePromptTokens,
    getFabricType,
    initFabricatorAvailableLeftTabs,
    currentVersionHistory,
    viewingVersionHistory,
    refineTab,
    refineStep,
    getHistoryDetailImages,
    getHistoryDetailImage,
    contentImage,
    contentButtonType,
    onClickContentButton,
    getIdAndStatus,
    refineAdjustWeight,
    showErrorModal,
  }
}

export default useFabricator
