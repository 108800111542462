<template>
  <div
    ref="refAppTooltip"
    :class="['app-tooltip', options.className, prepared ? 'prepared' : '']"
    :style="{ ...finalStyle, ...options.style }"
    @mouseleave="onMouseleave"
  >
    <div v-if="!tooltipComponent" class="tooltip-contents" v-html="$t(options.text)" />
    <component :is="tooltipComponent" v-else class="tooltip-contents" :params="options.params" />

    <div class="triangle" :style="trianglePosition" :class="{ 'go-up': triangleGoUp }" />
  </div>
</template>

<script setup>
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  options: {
    type: Object,
    default: () => ({}),
  },
})

const { plugins, store } = useGlobalHooks()

const prepared = ref(false)

const refAppTooltip = ref(null)

const finalStyle = ref({})

const trianglePosition = ref({})

const triangleGoUp = ref(false)

const setFinalStyle = () => {
  const { showAbove, isAvailableTooltipMousemove } = props.options
  const pointerEvents = isAvailableTooltipMousemove ? 'all' : 'none'

  finalStyle.value = { pointerEvents }

  triangleGoUp.value = false

  if (!showAbove || !refAppTooltip.value) return

  const { top: targetTop, left: targetLeft, width: targetWidth } = showAbove.getBoundingClientRect()
  const { width: contentsWidth, height: contentsHeight } = refAppTooltip.value.getBoundingClientRect()

  if (contentsWidth === 32 && contentsHeight === 32) return

  if (targetTop - contentsHeight - 76 > 0) {
    finalStyle.value.top = `${targetTop - contentsHeight - 8}px`
  } else {
    triangleGoUp.value = true
    finalStyle.value.top = `${targetTop + 32}px`
  }

  const distanceHor = window.innerWidth - targetLeft

  if (window.innerWidth < targetLeft + contentsWidth) {
    finalStyle.value.left = `${window.innerWidth - contentsWidth}px`

    trianglePosition.value = {
      left: 'initial',
      transform: 'initial',
      right: `${distanceHor - targetWidth / 2 - 8}px`,
    }
  } else {
    const left = targetLeft - contentsWidth / 2 + targetWidth / 2

    finalStyle.value.left = left < 0 ? 0 : `${left}px`

    if (left < 0) {
      trianglePosition.value = {
        left: `${targetLeft + targetWidth / 2}px`,
      }
    }
  }

  if (triangleGoUp.value) trianglePosition.value.top = '-8px'

  prepared.value = true
}

onMounted(() => {
  setTimeout(setFinalStyle, 10)
  setTimeout(setFinalStyle, 100)
})

watch(
  () => store.getters.scrollTop,
  () => {
    setFinalStyle()

    const { scrollClose, id } = props.options

    if (scrollClose) plugins.$tooltip.hide(id)
  },
)

const tooltipComponent = computed(() => {
  const { type } = props.options

  if (type === 'item-thumbnail-tooltip-base') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipBase'),
    )
  }

  if (type === 'item-thumbnail-tooltip-disp') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipDisp'),
    )
  }

  if (type === 'item-thumbnail-tooltip-normal') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipNormal'),
    )
  }

  if (type === 'item-thumbnail-tooltip-rough') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipRough'),
    )
  }

  if (type === 'item-thumbnail-tooltip-mtl') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipMtl'),
    )
  }

  if (type === 'item-thumbnail-tooltip-alpha') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipAlpha'),
    )
  }

  if (type === 'item-thumbnail-tooltip-ao') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipAo'),
    )
  }

  if (type === 'item-thumbnail-tooltip-color') {
    return defineAsyncComponent(() =>
      import('@/components/common/item-detail/item-thumbnail-tooltip/ItemThumbnailTooltipColor'),
    )
  }

  return false
})

const onMouseleave = () => {
  const { id, isAvailableTooltipMousemove } = props.options

  if (!isAvailableTooltipMousemove) return

  plugins.$tooltip.hide(id)
}
</script>

<style lang="scss">
.app-tooltip {
  --triangle-size: 8px;
  position: absolute;
  padding: 12px 16px;
  width: fit-content;
  max-width: 320px;
  font-weight: 300;
  font-size: 12px;
  background: var(--gs-37);
  border-radius: 8px;
  color: var(--white);
  opacity: 0;

  .tooltip-contents {
    display: flex;
    flex-direction: column;

    span > b {
      font-weight: 600;
    }

    > b {
      margin-bottom: 4px;
    }

    a {
      font-weight: 600;
      text-transform: uppercase;
      text-decoration: underline;
      color: var(--cyan-tx-100);
    }
  }

  .triangle {
    width: 0;
    height: 0;
    border-left: var(--triangle-size) solid transparent;
    border-right: var(--triangle-size) solid transparent;
    border-top: var(--triangle-size) solid var(--gs-37);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(var(--triangle-size) * -1);

    &.go-up {
      transform: scaleY(-1) !important;
      left: calc(50% - 8px);
    }
  }

  &.prepared {
    opacity: 1;
  }

  @media (max-width: 767px) {
    width: 240px;
  }
}
</style>
