import helpers from '@/helpers'

export default {
  name: 'creditHistory',
  keys: [
    {
      title: 'Dates (UTC)',
      column: 'date',
      class: 'credit-date',
      filter: ({ startAt, endAt, activity }) =>
        (activity === 'usage' ?
          `${helpers.dayjs(startAt).format('MMM DD, YYYY')} - ${
            endAt ? helpers.dayjs(endAt).format('MMM DD, YYYY') : 'Present'
          }` :
          `${helpers.dayjs(startAt).format('MMM DD, YYYY')}`),
    },
    {
      title: 'Plan',
      type: 'plan-badge',
      class: ({ subscriptionPlanType }) => `${subscriptionPlanType}-type`,
      column: 'subscriptionPlanDisplayName',
    },
    {
      title: 'Activity',
      column: 'activity',
      filter: ({ activity }) => helpers.template.case.toCapital(activity),
    },
    {
      title: 'Transaction',
      column: 'credit',
      filter: ({ credit, activity }) =>
        (credit === 0 ?
          '-' :
          `${activity === 'usage' ? '-' : '+'} ${credit}<span class="only-desktop"> ${helpers.template.pluralizeWithNumber('credit', credit)}</span>`),
      style: { fontWeight: '500', color: 'var(--gs-4d)' },
    },
  ],
}
