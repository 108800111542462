import * as Sentry from '@sentry/vue'

const useSentry = (app) => {
  const { host } = window.location
  if (host.startsWith('localhost') || host.startsWith('127.0.0.1')) return

  // Dev에선 모든 에러를 다 리포팅
  const isDev = process.env.NODE_ENV === 'development'
  const options = {
    dsn: 'https://9af6314a56b74401b19b540e7d733204@o147846.ingest.sentry.io/4504177525850112',
    environment: process.env.NODE_ENV,
    tracesSampleRate: 0.1,
  }
  if (isDev) {
    options.app = app
  } else {
    options.defaultIntegrations = false
  }

  Sentry.init(options)
}

export default {
  Sentry,
  useSentry,
}
