<template>
  <div class="app-foldable-contents">
    <div ref="refContents" class="contents" :style="{ ...foldableStyle, transition: prepared ? 'margin 0.4s' : '' }">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch, nextTick } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  isShow: Boolean,
  calibrationHeight: {
    type: Number,
    default: 0,
  },
})

const { plugins, store } = useGlobalHooks()

const refContents = ref()

const prepared = ref(false)

const foldableStyle = ref({ marginBottom: '0px' })

const setFoldableContentsHeight = () => {
  try {
    const { height } = refContents.value.getBoundingClientRect()
    contentsHeight.value = height
  } catch (error) {
    nextTick(setFoldableContentsHeight)
  }
}

const setFordableStyle = (isShow) => {
  setFoldableContentsHeight()

  if (isShow) {
    foldableStyle.value.marginBottom = '0px'
    return
  }

  foldableStyle.value.marginBottom = `-${calibrateHeight(contentsHeight.value - props.calibrationHeight)}px`
}

const calibrateHeight = (calibratedHeight) => (calibratedHeight < 0 ? 0 : calibratedHeight)

const contentsHeight = ref(0)

onMounted(async () => {
  setFordableStyle(props.isShow)

  await plugins.$helpers.sleep(10)
  prepared.value = true
})

watch(
  () => props.isShow,
  () => {
    setFordableStyle(props.isShow)
  },
)

watch([() => store.getters.windowInnerWidth, () => store.getters.windowInnerHeight], () => {
  setFordableStyle(props.isShow)
})
</script>

<style lang="scss" scoped>
.app-foldable-contents {
  display: flex;
  overflow: hidden;

  .contents {
    display: flex;
  }
}
</style>
