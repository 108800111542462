<template>
  <AppInput
    ref="refAppSearchInput"
    v-model="searchInputValue"
    class="app-search-input"
    :class="{ 'not-empty': !!searchInputValue }"
    inputType="search"
    :placeholder="placeholder"
    :inputIcon="inputIcon"
    :isShowClearButton="true"
    @key-down-enter="setPageQuery(searchInputValue)"
    @click-clear="onClickClear()"
  />
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  routeName: {
    type: String,
    required: true,
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputIcon: {
    type: String,
    default: 'far fa-magnifying-glass',
  },
  searchQuery: {
    type: String,
    required: true,
  },
  fixedQuries: {
    type: Object,
    default: () => ({}),
  },
})

const { router } = useGlobalHooks()

const refAppSearchInput = ref()

const setPageQuery = (searchInputValue) => {
  refAppSearchInput.value?.blurInput()

  const { query, path } = router.currentRoute.value

  router.push({
    path,
    query: Object.fromEntries(
      Object.entries({ ...query, ...props.fixedQuries, [props.searchQuery]: searchInputValue }).filter(
        (queryValue) => queryValue[1],
      ),
    ),
  })
}

const onClickClear = () => {
  const { name } = router.currentRoute.value

  if (name === props.routeName) {
    setPageQuery()
  }
}

const searchInputValue = ref()

const checkQuery = () => {
  const targetQuery = (router.currentRoute.value.query || {})[props.searchQuery]

  searchInputValue.value = targetQuery
}

watch(
  () => router.currentRoute.value,
  () => {
    const { name } = router.currentRoute.value

    if (name === props.routeName) {
      checkQuery()
    }
  },
)

onMounted(checkQuery)
</script>

<style lang="scss" scoped>
.app-search-input.app-input {
  max-width: 756px;
  height: 56px;
  margin-bottom: 20px;
  transition: border-color 0.4s, box-shadow 0.4s;

  &.focused,
  &.not-empty {
    border-color: var(--purple-fds-50);
    box-shadow: 0px 0px 16px var(--purple-fds-10);
  }

  @media (max-width: 991px) {
    max-width: unset;
    height: 48px;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    height: 48px;
  }
}
</style>
