import { callWrappedApi } from '@/modules/axios'

const digitizationOrderService = {
  draftOrder: {
    all: (params) => callWrappedApi({ method: 'get', url: 'library/draft_orders', params }),
    detail: (id, params) => callWrappedApi({ method: 'get', url: `/library/draft_orders/${id}`, params }),
    delete: (id) => callWrappedApi({ method: 'delete', url: `/library/draft_orders/${id}` }),
    update: (id, data) => callWrappedApi({ method: 'put', url: `/library/draft_orders/${id}`, data }),
    create: () => callWrappedApi({ method: 'post', url: '/library/draft_orders/' }),
    checkout: (id, data) => callWrappedApi({ method: 'post', url: `/library/draft_orders/${id}/checkout`, data }),
    checkoutComplete: (data) => callWrappedApi({ method: 'post', url: '/library/checkout/order/complete', data }),
    createPurchaseOrder: (id, data) =>
      callWrappedApi({ method: 'post', url: `/library/draft_orders/${id}/purchase_order`, data }),
    document: (id, documentType) =>
      callWrappedApi({ method: 'post', url: `/library/draft_orders/${id}/document`, data: { documentType } }),
    createInvoice: (id, data) => callWrappedApi({ method: 'post', url: `/library/draft_orders/${id}/invoice`, data }),
    share: (id, data) => callWrappedApi({ method: 'post', url: `/library/draft_orders/${id}/invoice/share`, data }),
  },
  draftOrderItem: {
    all: (params) => callWrappedApi({ method: 'get', url: '/library/draft_order_assets', params }),
    count: (draftOrderId) =>
      callWrappedApi({ method: 'get', url: '/library/draft_order_assets/count', params: { draftOrderId } }),
    create: (data) => callWrappedApi({ method: 'post', url: 'library/draft_order_assets', data }),
    update: (id, data) => callWrappedApi({ method: 'put', url: `/library/draft_order_assets/${id}`, data }),
    delete: (id) => callWrappedApi({ method: 'delete', url: `/library/draft_order_assets/${id}` }),
  },
  order: {
    count: () => callWrappedApi({ method: 'get', url: '/library/orders/count' }),
    all: (params) => callWrappedApi({ method: 'get', url: '/library/orders', params }),
    detail: (id, params) => callWrappedApi({ method: 'get', url: `/library/orders/${id}`, params }),
    update: (id, data) => callWrappedApi({ method: 'put', url: `/library/orders/${id}`, data }),
    document: (id, documentType) =>
      callWrappedApi({ method: 'post', url: `/library/orders/${id}/document`, data: { documentType } }),
    share: (id, data) => callWrappedApi({ method: 'post', url: `/library/orders/${id}/document/share`, data }),
    checkout: (id, data) => callWrappedApi({ method: 'post', url: `/library/orders/${id}/checkout`, data }),
    checkComplete: (id) => callWrappedApi({ method: 'get', url: `/library/orders/${id}/check/processing` }),
    detailCSV: (id) => callWrappedApi({ method: 'post', url: `/library/orders/${id}/csv` }),
  },
  orderItem: {
    all: (params) => callWrappedApi({ method: 'get', url: '/library/order_assets', params }),
  },
  colorFilters: () => callWrappedApi({ method: 'get', url: '/library/colors' }),
  compositionFilters: () => callWrappedApi({ method: 'get', url: '/library/digitization_contents' }),
  digitizationOrderOptions: () => callWrappedApi({ method: 'get', url: '/library/orders/options' }),
  presets: {
    document: (id, documentType) =>
      callWrappedApi({ method: 'post', url: `/library/presets/${id}/document`, data: { documentType } }),
  },
}

export default digitizationOrderService
