import helpers from '@/helpers'

export default {
  name: 'paymentHistory',
  keys: [
    {
      column: 'date',
      class: 'payment-date',
      filter: ({ transactedAt }) => helpers.dayjs(transactedAt).format('MMM DD, YYYY'),
    },
    {
      column: 'isScheduled',
      filter: ({ status, id, transactionType }) => {
        if (!id) {
          return '<span style="color: var(--white-70);">Scheduled</span>'
        }

        if (status === 'failed') {
          return '<span style="color: var(--status-error-100);">Payment failed</span>'
        }

        if (transactionType === 'refund') {
          return '<span style="color: var(--status-error-100);">Refunded</span>'
        }

        return null
      },
    },
    {
      column: 'plan',
      class: 'payment-target',
      filter: ({ productName, interval }) => {
        if (productName.includes('Single Purchase')) return 'Single Purchase'

        if (!interval) return productName

        return `${productName} (${interval})`
      },
    },
    {
      column: 'price',
      type: 'payment-price',
      filter: ({ amount, status }) => {
        const refinedPrice = helpers.template.prettyPrice(Math.abs(amount))
        const style = status === 'failed' ? 'text-decoration: line-through;' : ''

        return `<span style="${style}">
        ${amount < 0 ? '-' : ''} $ ${refinedPrice}
        </span>`
      },
    },
  ],
}
