/* eslint-disable camelcase */
import { computed } from 'vue'
import { router } from '@/router'
import helpers from '@/helpers'
import { store as $store } from '@/store'

const useMarketingReferrer = () => {
  const loadMarketingReferrersFromUrl = async () => {
    await helpers.sleep(100)
    const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = router.currentRoute.value.query

    if (!utm_source) return

    const key = Math.floor(new Date().getTime() / 1000)
    const value = `${utm_source || ''}/${utm_medium || ''}/${utm_campaign || ''}/${utm_term || ''}/${utm_content || ''}`

    const result = { [key]: value }

    return result
  }

  const init = async () => {
    const result = await loadMarketingReferrersFromUrl()
    if (!result) return

    $store.dispatch('setMarketingReferrerHeader', result)
  }

  const currentReferralCampaign = computed(() => {
    const { me, referralCampaigns, header } = $store.getters

    const activatedReferralCampaign = JSON.parse((header || {})['marketing-referrer'] || 'null')

    if (me || (referralCampaigns || []).length === 0 || !activatedReferralCampaign) {
      return null
    }

    const keys = ['source', 'medium', 'campaign']

    const activatedReferralCampaignQuery = Object.values(activatedReferralCampaign)
      .pop()
      .split('/')
      .splice(0, 3)
      .join('/')

    return referralCampaigns.find((o) => keys.map((key) => o[key]).join('/') === activatedReferralCampaignQuery)
  })

  return {
    init,
    currentReferralCampaign,
  }
}

export default useMarketingReferrer
