<template>
  <RouterLink
    class="app-link-button"
    :to="to"
    :style="{
      background,
      borderColor: refinedBorderColor,
      color: fontColor,
    }"
  >
    <div class="button-overlay" :class="[`hover-theme-${hoverTheme}`]" />
    <slot />
  </RouterLink>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  to: {
    type: String,
    required: true,
  },
  buttonType: {
    type: String,
    default: 'solid',
    validator: (val) => ['solid', 'line'].includes(val),
  },
  background: {
    type: String,
    default: 'var(--gs-21)',
  },
  borderColor: {
    type: String,
    default: 'var(--gs-21)',
  },
  fontColor: {
    type: String,
    default: 'var(--white)',
  },
  hoverTheme: {
    type: String,
    default: 'white',
    validator: (val) => ['white', 'black'].includes(val),
  },
  disabled: Boolean,
})

const refinedBorderColor = computed(() => {
  const { buttonType, background, borderColor } = props

  // if (borderColor) return borderColor

  if (buttonType === 'solid') {
    return background
  }

  return borderColor
})
</script>

<style lang="scss" scoped>
.app-link-button {
  z-index: 1;
  position: relative;
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid;
  transition: background-color 0.4s, border-color 0.4s, color 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;

  .button-overlay {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: transparent;
    user-select: none;
    pointer-events: none;
    transition: background-color 0.4s;
  }

  &:hover .button-overlay {
    .hover-theme-white {
      background-color: var(--white-20);
    }

    .hover-theme-black {
      background-color: var(--black-5);
    }
  }

  &:active .button-overlay {
    background-color: var(--black-10) !important;
  }

  &:disabled {
    background-color: var(--gs-f8) !important;
    border-color: var(--gs-e0) !important;
    color: var(--gs-c5) !important;
    pointer-events: none;
  }

  @media (max-width: 991px) {
    height: 40px;
    font-size: 14px;
  }
}
</style>
