import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { store as $store } from '@/store'
import cdn from './cdn'
import dom from './dom'
import meta from './meta'
import regex from './regex'
import template from './template'
import translate from './translate'
import sentryVue from './sentry-vue'
import addons from './addons'
import color from './color'

dayjs.extend(utc)

const helpers = {
  meta,
  ...addons,
  dayjs,
  withCdn: cdn.withCdn,
  regex,
  dom,
  template,
  translate,
  localStorage: {
    setMeta: (key, value) => {
      const metaObj = JSON.parse(window.localStorage.getItem('meta') || '{}')
      metaObj[key] = value
      window.localStorage.setItem('meta', JSON.stringify(metaObj))
    },
    getMeta: (key) => JSON.parse(window.localStorage.getItem('meta') || '{}')[key],
    deleteMeta: (key) => {
      const metaObj = JSON.parse(window.localStorage.getItem('meta') || '{}')
      delete metaObj[key]
      window.localStorage.setItem('meta', JSON.stringify(metaObj))
    },
  },
  // eslint-disable-next-line no-promise-executor-return
  sleep: (ms) => new Promise((resolve) => setTimeout(resolve, ms)),
  gtm: (object) => {
    if ($store.getters.isSSR || typeof window === 'undefined' || !window.dataLayer) return

    window.dataLayer.push(object)
  },
  openNewTab: async (url, attributes, type = 'click-a-tag') => {
    if (type === 'click-a-tag') {
      const link = document.createElement('a')
      link.setAttribute('href', url)

      const attributeEntries = Object.entries(attributes || {})

      if (attributeEntries.findIndex(([key]) => key === 'download') === -1) {
        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'noopener noreferrer')
      }

      attributeEntries.forEach(([key, value]) => {
        link.setAttribute(key, value)
      })

      link.click()
      return
    }

    if (type === 'window-open') {
      const newTab = window.open(url, '_blank')

      if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
        addons.modal.basicV2({
          titleIcon: 'error-v2',
          body: 'Pop-up window is blocked on your browser. In order to download files, your pop-up window must be allowed.<br/><br/><a href="https://support.vmod.xyz/hc/en-us/articles/8952023816473-How-to-allow-your-pop-up-window-in-Safari" target="_blank">Learn How</a>',
        })

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Blocked')
      }
    }
  },
  reportError: (error) => {
    const { code } = error.response?.data || {}
    const { url, method, data, params } = error.config || {}

    sentryVue.Sentry.captureException(error, {
      tags: { ssr: false, url, method, data, params, errorCode: code },
    })
  },
  reportMessage: (message) => {
    sentryVue.Sentry.captureMessage(message)
  },
  countryImg: (code) => {
    if (!code) return ''

    return `https://raw.githubusercontent.com/lipis/flag-icon-css/d81077e4e9648ba32546fad3b77932b48feb344b/flags/4x3/${code.toLowerCase()}.svg`
  },
  color,
}

export default helpers
