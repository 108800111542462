/* eslint-disable no-param-reassign */
import digitizationOrderService from '@/services/digitizationOrder'
import useDigitizationOrders from '@/hooks/digitization-orders'

const { getDigitizationOrdersInfo, digitizationOrdersLimit } = useDigitizationOrders()

const digitizationOrder = {
  state: () => ({
    digitizationOrderOptions: {
      backScan: 20,
      faceScan: 20,
      physicalPropertyMeasure: 30,
    },
    draftedDigitizationOrder: null,
    draftedDigitizationOrderItems: null,
    editedDigitizationOrderItem: null,
    digitizationOrderDetail: null,
    digitizationOrderItems: null,
    digitizationOrderColorFilters: null,
    digitizationOrderCompositionFilters: null,
    allDigitizationOrdersInfos: null,
  }),
  getters: {
    digitizationOrderOptions: ({ digitizationOrderOptions }) => digitizationOrderOptions,
    draftedDigitizationOrder: ({ draftedDigitizationOrder }) => draftedDigitizationOrder,
    draftedDigitizationOrderItems: ({ draftedDigitizationOrderItems }) => draftedDigitizationOrderItems,
    editedDigitizationOrderItem: ({ editedDigitizationOrderItem }) => editedDigitizationOrderItem,
    digitizationOrderDetail: ({ digitizationOrderDetail }) => digitizationOrderDetail,
    digitizationOrderItems: ({ digitizationOrderItems }) => digitizationOrderItems,
    digitizationOrderColorFilters: ({ digitizationOrderColorFilters }) => digitizationOrderColorFilters,
    digitizationOrderCompositionFilters: ({ digitizationOrderCompositionFilters }) =>
      digitizationOrderCompositionFilters,
    allDigitizationOrdersInfos: ({ allDigitizationOrdersInfos }) => allDigitizationOrdersInfos,
  },
  actions: {
    async loadDigitizationOrderOptions({ commit }) {
      try {
        const data = await digitizationOrderService.digitizationOrderOptions()
        commit('setDigitizationOrderOptions', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async createNewDigitizationOrder({ commit }) {
      try {
        const data = await digitizationOrderService.draftOrder.create()
        commit('setDraftedDigitizationOrder', data)
        return data
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDraftedOrderDetail({ commit }, { id, shared }) {
      try {
        const [data, allCount] = await Promise.all([
          digitizationOrderService.draftOrder.detail(id, { shared }),
          digitizationOrderService.draftOrderItem.count(id),
        ])

        commit('setDraftedDigitizationOrder', { ...data, allCount: allCount.count })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDraftedDigitizationOrderItems({ commit }, params) {
      try {
        const { data, count } = await digitizationOrderService.draftOrderItem.all(params)
        commit('setDraftedDigitizationOrderItems', data)

        return count
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async loadDigitizationOrderColorFilters({ commit, getters }) {
      if (!getters.digitizationOrderColorFilters) {
        try {
          const data = await digitizationOrderService.colorFilters()
          commit('setDigitizationOrderColorFilters', data)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    },
    async loadDigitizationOrderComposotionFilters({ commit, getters }) {
      if (!getters.digitizationOrderCompositionFilters) {
        try {
          const data = await digitizationOrderService.compositionFilters()
          commit('setDigitizationOrderCompositionFilters', data)
        } catch (error) {
          return Promise.reject(error)
        }
      }
    },
    async getOrderDetail({ commit }, { id, shared }) {
      try {
        const data = await digitizationOrderService.order.detail(id, { shared })
        commit('setDigitizationOrderDetail', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async getDigitizationOrderItems({ commit }, params) {
      try {
        const { data, count } = await digitizationOrderService.orderItem.all(params)
        commit('setDigitizationOrderItems', data)

        return count
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async loadAllDigitizationOrders(
      { commit, getters },
      { pendingPage, page, paymentPending, status, isForcedLoadPendingOrders, isForcedLoadOrders },
    ) {
      const loadDraftDigitizationOrders = async ({ limit, pageValue }) => {
        const info = getDigitizationOrdersInfo(getters.allDigitizationOrdersInfos, 'draft-orders')

        if (info.page === pageValue && !isForcedLoadPendingOrders) return info

        try {
          const res = await digitizationOrderService.draftOrder.all({ offset: (pageValue - 1) * limit, limit })

          return { type: 'draft-orders', page: pageValue, ...res }
        } catch (error) {
          return Promise.reject(error)
        }
      }

      const loadDigitizationOrders = async ({ limit, pageValue, status: statusValue }) => {
        const info = getDigitizationOrdersInfo(getters.allDigitizationOrdersInfos, 'orders')

        if (info.page === pageValue && !isForcedLoadOrders) return info

        try {
          const availableStatus = ['initiated', 'processing', 'completed', 'refunded', 'cancelled']
          const res = await digitizationOrderService.order.all({
            offset: (pageValue - 1) * limit,
            limit,
            needPayment: paymentPending === 'true',
            status: availableStatus.includes(statusValue) ? statusValue : null,
          })

          return { type: 'orders', page: pageValue, ...res }
        } catch (error) {
          return Promise.reject(error)
        }
      }

      const limit = digitizationOrdersLimit

      try {
        const infos = await Promise.all([
          loadDraftDigitizationOrders({ limit, pageValue: parseInt(pendingPage || 1, 10) }),
          loadDigitizationOrders({ limit, pageValue: parseInt(page || 1, 10), status }),
        ])

        commit('setAllDigitizationOrdersInfos', infos)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mutations: {
    setDigitizationOrderOptions(state, options) {
      state.digitizationOrderOptions = options
    },
    setDraftedDigitizationOrder(state, draftedOrder) {
      state.draftedDigitizationOrder = draftedOrder
    },
    setDraftedDigitizationOrderItems(state, items) {
      state.draftedDigitizationOrderItems = items
    },
    setEditedDigitizationOrderItem(state, item) {
      state.editedDigitizationOrderItem = item
    },
    setDigitizationOrderColorFilters(state, filters) {
      state.digitizationOrderColorFilters = filters
    },
    setDigitizationOrderCompositionFilters(state, filters) {
      state.digitizationOrderCompositionFilters = filters
    },
    setDigitizationOrderDetail(state, order) {
      state.digitizationOrderDetail = order
    },
    setDigitizationOrderItems(state, items) {
      state.digitizationOrderItems = items
    },
    setAllDigitizationOrdersInfos(state, infos) {
      state.allDigitizationOrdersInfos = infos
    },
  },
}

export default digitizationOrder
