<template>
  <div
    :id="contentsId"
    ref="refAppTooltipIcon"
    class="app-tooltip-icon-v2"
    @mouseover="onMouseOverTooltipIcon"
    @click="onClickTooltipIcon"
  >
    <i v-if="iconClassName" class="target-icon" :class="iconClassName" />
    <slot name="contents" />

    <AppTeleport v-if="isShowTooltip" to="#app">
      <div ref="refAppTooltip" class="app-tooltip-v2" :style="tooltipStyles.tooltip">
        <div class="app-tooltip-v2-contents">
          <slot name="tooltip" />
        </div>
      </div>

      <div class="app-tooltip-v2-triangle" :style="tooltipStyles.triangle" />
    </AppTeleport>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  contentsId: {
    type: String,
    default: '',
  },
  iconClassName: {
    type: String,
    default: 'fal fa-circle-question',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const { store } = useGlobalHooks()

const isShowTooltip = ref(false)
const onMouseOverTooltipIcon = () => {
  if (props.disabled || store.getters.isTablet) return

  isShowTooltip.value = true
  window.addEventListener('mousemove', onMouseMove)
}

const onTouchMove = (event) => {
  onMouseMove(event.touches[0])
}

const onClickTooltipIcon = () => {
  if (props.disabled || !store.getters.isTablet) return

  isShowTooltip.value = true
  window.addEventListener('touchstart', onTouchMove)
  window.addEventListener('touchmove', onTouchMove)
}

const onMouseMove = ({ target }) => {
  if (
    target.id === props.contentsId ||
    target.className.includes('target-icon') ||
    target.className.includes('app-tooltip-v2') ||
    target.className.includes('app-tooltip-v2-triangle') ||
    target.className.includes('app-tooltip-v2-contents')
  ) {
    return
  }

  hideTooltip()
}

const hideTooltip = () => {
  window.removeEventListener('mousemove', onMouseMove)
  window.removeEventListener('touchstart', onTouchMove)
  window.removeEventListener('touchmove', onTouchMove)
  isShowTooltip.value = false
}

const refAppTooltipIcon = ref()
const refAppTooltip = ref()
const tooltipStyles = computed(() => {
  const { top: iconTop, left: iconLeft, width: iconWidth } = refAppTooltipIcon.value.getBoundingClientRect()
  const {
    left: tooltipLeft,
    right: tooltipRight,
    width: tooltipWidth,
  } = refAppTooltip.value?.getBoundingClientRect() || {}

  const refinedLeft = (() => {
    if (iconWidth < 16) return iconLeft

    return iconLeft + (iconWidth - 16) / 2
  })()

  const triangleStyle = {
    top: `${iconTop}px`,
    left: `${refinedLeft}px`,
  }

  if (tooltipLeft < 0) {
    return {
      tooltip: {
        top: `${iconTop}px`,
        left: `${tooltipWidth / 2}px`,
      },
      triangle: triangleStyle,
    }
  }

  if (store.getters.windowInnerWidth < tooltipRight) {
    return {
      tooltip: {
        top: `${iconTop}px`,
        left: `${store.getters.windowInnerWidth - tooltipWidth / 2 - 16}px`,
      },
      triangle: triangleStyle,
    }
  }

  return {
    tooltip: {
      top: `${iconTop}px`,
      left: `${refinedLeft}px`,
    },
    triangle: triangleStyle,
  }
})

defineExpose({
  hideTooltip,
})
</script>

<style lang="scss">
.app-tooltip-icon-v2 {
  position: relative;
  display: flex;

  .target-icon {
    display: flex;
    width: 20px;
    height: 16px;
    justify-content: center;
    align-items: center;
    color: var(--white-70);
    font-size: 12px;
    cursor: pointer;
  }
}

.app-tooltip-v2 {
  z-index: 30;
  transform: translate(calc(-50% + 8px), -100%);
  max-width: 300px;
  width: max-content;
  position: fixed;
  padding-bottom: 8px;

  > div {
    color: var(--white);
    font-size: 11px;
    font-weight: 400;
    display: block;
    padding: 8px 12px;
    border-radius: 8px;
    background: var(--black-50);
    backdrop-filter: blur(10px);

    a {
      text-decoration: underline;
      cursor: pointer;
    }

    b {
      font-weight: 600;
    }
  }
}

.app-tooltip-v2-triangle {
  z-index: 12;
  transform: translate(0, -100%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--black-50);
  position: absolute;
}
</style>
