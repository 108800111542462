<template>
  <AppHeader />
  <EmailVerificationBanner v-if="isShowEmailVerificationBanner" />
</template>

<script setup>
import { computed, defineAsyncComponent } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const { store } = useGlobalHooks()

const AppHeader = defineAsyncComponent(() => import('@/components/app/app-header/AppHeader'))
const EmailVerificationBanner = defineAsyncComponent(() => import('./EmailVerificationBanner'))

const isShowEmailVerificationBanner = computed(() => store.getters.headerContentsOptions?.isShowEmailVerificationBanner)
</script>
