const color = {
  // eslint-disable-next-line no-bitwise
  convertRGBToHex: (r, g, b) => ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0'),
  convertHSLToRGB: (h, s, l) => {
    const newLightness = l / 100

    const k = (n) => (n + h / 30) % 12

    const a = (s / 100) * Math.min(newLightness, 1 - newLightness)

    const calculateRGBValue = (n) =>
      Math.round((newLightness - a * Math.max(-1, Math.min(k(n) - 3, Math.min(9 - k(n), 1)))) * 255)

    return { r: calculateRGBValue(0), g: calculateRGBValue(8), b: calculateRGBValue(4) }
  },
  convertHexToRGB: (hex) => {
    let h = hex

    if (h.length === 3) {
      h = [...h].map((x) => x + x).join('')
    }

    h = parseInt(h, 16)

    // eslint-disable-next-line no-bitwise
    return { r: parseInt(h >>> 16, 10), g: parseInt((h & 0x00ff00) >>> 8, 10), b: parseInt((h & 0x0000ff) >>> 0, 10) }
  },
  convertRGBToHSL: (r, g, b) => {
    // eslint-disable-next-line no-param-reassign
    r /= 255
    // eslint-disable-next-line no-param-reassign
    g /= 255
    // eslint-disable-next-line no-param-reassign
    b /= 255

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    const diff = max - min

    let h = 0
    let s = 0
    let l = (max + min) / 2

    if (diff !== 0) {
      s = diff / (1 - Math.abs(2 * l - 1))

      // eslint-disable-next-line default-case
      switch (max) {
        case r:
          h = ((g - b) / diff + (g < b ? 6 : 0)) * 60
          break
        case g:
          h = ((b - r) / diff + 2) * 60
          break
        case b:
          h = ((r - g) / diff + 4) * 60
          break
      }

      h = Math.round(h)
      s = Math.round(s * 100)
      l = Math.round(l * 100)
    } else {
      l = Math.round(r * 100)
    }

    return { h, s, l }
  },
}

export default color
