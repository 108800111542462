import { store as $store } from '@/store'

const template = {
  case: {
    toSnake: (str, delim) => (str || '').replace(/[A-Z]/g, (letter) => `${delim || '_'}${letter.toLowerCase()}`),
    toCamel: (str) => str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', '')),
    toCapital: (str) => str.charAt(0).toUpperCase() + str.slice(1),
    toPascal(str) {
      return this.toCapital(this.toCamel(str))
    },
    toConst(str) {
      return this.toSnake(str).toUpperCase()
    },
    pluralize: (str) => {
      if (str.endsWith('day')) return `${str}s`
      if (str.endsWith('way')) return `${str}s`
      if (str.endsWith('y')) return `${str.slice(0, -1)}ies`
      if (str.endsWith('s') || str.endsWith('h')) return `${str}es`

      return `${str}s`
    },
    underscoreToDash: (str = '') => str.replaceAll('_', '-'),
  },
  // EX:) pluralizeWithNumber('%s bundle', 3) === '3 bundles'
  pluralizeWithNumber: (format, number) => {
    if (!format) return ''

    const assigned = format.replace(/%s/, number)
    return number > 1 ? template.case.pluralize(assigned) : assigned
  },
  currency: (currency) => {
    const symbols = { usd: '$', gbp: '£', eur: '€', krw: '₩' }
    if (currency) return symbols[currency]

    if (!$store.getters.me) return '₩'

    return symbols[$store.getters.me.currency]
  },
  withLeadingZero: (value, numDigits) => {
    if (typeof value !== 'number' || !numDigits) return

    const numDigitsOfGivenValue = value.toString().length
    return `${'0'.repeat(numDigits - numDigitsOfGivenValue)}${value}`
  },
  supply: (left, total) => `${left} / ${total}`,
  prettyPrice: (price, { maximumFractionDigits = 2, minimumFractionDigits = 2 } = {}) => {
    if (typeof parseFloat(price) !== 'number') return 0

    return parseFloat(price).toLocaleString(undefined, {
      maximumFractionDigits,
      minimumFractionDigits,
    })
  },
  commaSepToArray: (commaSepString, groupBy) => {
    if (!commaSepString) return []

    const splitted = (commaSepString || '').split(',')
    if (!groupBy) return splitted || []

    const arr = []
    splitted.forEach((tag) => (arr.find((o) => o === tag) ? null : arr.push(tag)))
    return arr
  },
}

export default template
