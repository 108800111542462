<template>
  <AppTeleport to="#app">
    <div class="wrapper-overlay-contents" :class="overlayClassName">
      <div class="overlay-background" @click="onClickOverlay" />
      <transition :name="refinedTransitionName">
        <div v-show="prepared" class="contents" :style="refinedStyle">
          <slot />
        </div>
      </transition>
    </div>
  </AppTeleport>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const emit = defineEmits(['delete-contents'])

const props = defineProps({
  mountOrigin: {
    type: Object,
    required: true,
  },
  mountedPosition: {
    type: String,
    default: 'right',
    validator: (val) =>
      [
        'right',
        'right-full-width',
        'right-no-top',
        'right-mobile',
        'left',
        'left-full-width',
        'left-mobile',
        'bottom',
        'bottom-right',
        'bottom-full-width',
        'bottom-mobile',
        'up',
      ].indexOf(val) !== -1,
  },
  overlayClassName: {
    type: String,
    default: '',
  },
  contentsHeight: {
    type: Number,
    default: 0,
  },
  options: {
    type: String,
    default: '',
  },
})

const { plugins, store } = useGlobalHooks()

const refinedStyle = computed(() => {
  const { contentsHeight, mountedPosition, mountOrigin } = props
  if (!mountOrigin) return {}

  const {
    top: targetTop,
    left: targetLeft,
    right: targetRight,
    height: targetHeight,
  } = mountOrigin.getBoundingClientRect()
  const { windowInnerWidth, windowInnerHeight } = store.getters
  const refinedTop = (() => {
    const newTop = windowInnerHeight - contentsHeight - 16

    if (newTop < 136 && props.options.includes('sticky-top')) return 136

    if (newTop < targetTop) return newTop

    return targetTop
  })()

  if (mountedPosition === 'right') {
    return {
      top: `${refinedTop}px`,
      left: `${targetRight}px`,
    }
  }

  if (['right-full-width', 'left-full-width'].includes(mountedPosition)) {
    return {
      top: `${refinedTop}px`,
      left: 0,
      right: 0,
    }
  }

  if (['right-mobile', 'left-mobile', 'bottom-mobile'].includes(mountedPosition)) {
    return {
      top: '16px',
      left: '16px',
      right: '16px',
    }
  }

  if (mountedPosition === 'right-no-top') {
    return {
      left: `${targetRight}px`,
    }
  }

  if (mountedPosition === 'left') {
    return {
      top: `${refinedTop}px`,
      right: `${windowInnerWidth - targetLeft}px`,
    }
  }

  if (mountedPosition === 'bottom') {
    return {
      top: `${targetTop + targetHeight}px`,
      left: `${targetLeft}px`,
    }
  }

  if (mountedPosition === 'bottom-right') {
    return {
      top: `${targetTop + targetHeight}px`,
      right: `${windowInnerWidth - targetRight}px`,
    }
  }

  if (mountedPosition === 'bottom-full-width') {
    return {
      top: `${targetTop + targetHeight}px`,
      left: 0,
      right: 0,
    }
  }

  if (mountedPosition === 'up') {
    return {
      bottom: `${windowInnerHeight - targetTop}px`,
      left: `${targetLeft}px`,
    }
  }

  return {}
})

const prepared = ref(false)
onMounted(() => {
  prepared.value = true
})

const onClickOverlay = ({ target }) => {
  if (!target.className.includes('overlay-background') || props.options.includes('block-background-close')) return

  closeContents()
}

const closeContents = async () => {
  prepared.value = false

  await plugins.$helpers.sleep(300)
  emit('delete-contents')
}

const refinedTransitionName = ref(
  (() => {
    const { mountedPosition } = props

    if (['bottom-right', 'bottom-full-width', 'bottom-mobile'].includes(mountedPosition)) return 'slide-bottom'

    if (['right-full-width', 'right-no-top', 'right-mobile'].includes(mountedPosition)) return 'slide-right'

    if (['left-full-width', 'left-mobile'].includes(mountedPosition)) return 'slide-left'

    return `slide-${mountedPosition}`
  })(),
)

defineExpose({
  prepared,
  closeContents,
})
</script>

<style lang="scss" scoped>
.wrapper-overlay-contents {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  * {
    pointer-events: auto;
  }

  .overlay-background {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .slide-right-enter-active,
  .slide-right-leave-active,
  .slide-left-enter-active,
  .slide-left-leave-active,
  .slide-bottom-enter-active,
  .slide-bottom-leave-active,
  .slide-up-enter-active,
  .slide-up-leave-active {
    transition: all 0.3s ease;
  }

  .slide-right-enter-from,
  .slide-right-leave-to {
    opacity: 0;
    transform: translateX(-40px);
  }

  .slide-left-enter-from,
  .slide-left-leave-to {
    opacity: 0;
    transform: translateX(40px);
  }

  .slide-bottom-enter-from,
  .slide-bottom-leave-to {
    opacity: 0;
    transform: translateY(-40px);
  }

  .slide-up-enter-from,
  .slide-up-leave-to {
    opacity: 0;
    transform: translateY(40px);
  }
}

.contents {
  position: absolute;
}
</style>
