/* eslint-disable no-param-reassign */
import axios from 'axios'

const fabricatorAdmin = {
  state: () => ({
    fabricatorAdminUser: null,
    fabricatorAdminToken: null,
    fabricatorAdminLoadedData: null,
    fabricatorAdminPendingCount: null,
    fabricatorAdminTaskCount: null,
    fabricatorAdminTargetTask: null,
    fabricatorAdminCategories: null,
    fabricatorAdminSelectedCategoryOption: null,
    fabricatorAdminLaboratoryOptions: null,
  }),
  getters: {
    fabricatorAdminUser: ({ fabricatorAdminUser }) => fabricatorAdminUser,
    fabricatorAdminToken: ({ fabricatorAdminToken }) => fabricatorAdminToken,
    fabricatorAdminLoadedData: ({ fabricatorAdminLoadedData }) => fabricatorAdminLoadedData,
    fabricatorAdminPendingCount: ({ fabricatorAdminPendingCount }) => fabricatorAdminPendingCount,
    fabricatorAdminTaskCount: ({ fabricatorAdminTaskCount }) => fabricatorAdminTaskCount,
    fabricatorAdminTargetTask: ({ fabricatorAdminTargetTask }) => fabricatorAdminTargetTask,
    fabricatorAdminCategories: ({ fabricatorAdminCategories }) => fabricatorAdminCategories,
    fabricatorAdminSelectedCategoryOption: ({ fabricatorAdminSelectedCategoryOption }) =>
      fabricatorAdminSelectedCategoryOption,
    fabricatorAdminLaboratoryOptions: ({ fabricatorAdminLaboratoryOptions }) => fabricatorAdminLaboratoryOptions,
  },
  actions: {
    fabricatorAdminLogin: async ({ commit, dispatch }, { email, password, isAutoLogin }) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        const { data } = await axios({
          url: '/collection/colletors/sign_in',
          method: 'post',
          baseURL,
          data: { email, password },
        })

        commit('setFabricatorAdminToken', data.token)

        if (isAutoLogin) {
          window.localStorage.setItem('fabricatorAdminToken', data.token)
        }

        window.localStorage.setItem('fabricatorAdminEmail', email)

        await dispatch('fabricatorAdminGetUser')
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminGetUser: async ({ commit, getters }) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        const { data } = await axios({
          url: '/collection/colletors/me',
          method: 'get',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
        })

        commit('setFabricatorAdminUser', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminLogout: ({ commit }) => {
      commit('setFabricatorAdminUser', null)
      commit('setFabricatorAdminToken', null)
      commit('setFabricatorAdminLoadedData', null)
      commit('setFabricatorAdminPendingCount', null)
      commit('setFabricatorAdminTaskCount', null)
      commit('setFabricatorAdminTargetTask', null)
      commit('setFabricatorAdminCategories', null)
      commit('setFabricatorAdminSelectedCategoryOption', null)
      commit('setFabricatorAdminLaboratoryOptions', null)

      window.localStorage.clear()
    },
    fabricatorAdminLoadData: async ({ commit, getters }, { type, offset, limit }) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        const { data } = await axios({
          url: `/collection/tasks/${type}`,
          method: 'get',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
          params: { offset, limit },
        })

        commit('setFabricatorAdminLoadedData', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminLoadPendingCount: async ({ commit, getters }) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        const { data } = await axios({
          url: '/collection/tasks/pending_count',
          method: 'get',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
        })

        commit('setFabricatorAdminPendingCount', data.count)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminLoadTaskCount: async ({ commit, getters }) => {
      try {
        const defaultParams = {
          method: 'get',
          baseURL: process.env.VUE_APP_API_DOMAIN,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
        }

        const [pending, completed] = await Promise.all([
          axios({
            url: '/collection/tasks/pending',
            ...defaultParams,
          }),
          axios({
            url: '/collection/tasks/completed',
            ...defaultParams,
          }),
        ])

        const getCount = ({ data }) => data.count

        commit('setFabricatorAdminTaskCount', {
          pending: getCount(pending),
          completed: getCount(completed),
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminAssignNewTasks: async ({ getters }, itemNum) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        await axios({
          url: '/collection/tasks/assign',
          method: 'post',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
          data: { itemNum },
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminReturnPendingTasks: async ({ getters }) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        await axios({
          url: '/collection/tasks/cancel',
          method: 'delete',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminLoadTaskDetail: async ({ getters, commit }, id) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        const { data } = await axios({
          url: `/collection/tasks/${id}`,
          method: 'get',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
        })

        commit('setFabricatorAdminTargetTask', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminLoadCategories: async ({ getters, commit }) => {
      if (getters.fabricatorAdminCategories) return

      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        const { data } = await axios({
          url: '/collection/categories',
          method: 'get',
          baseURL,
        })

        commit('setFabricatorAdminCategories', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    fabricatorAdminSetKeyword: async ({ getters }, { targetId, params }) => {
      try {
        const baseURL = process.env.VUE_APP_API_DOMAIN

        await axios({
          url: `/collection/tasks/${targetId}`,
          method: 'put',
          baseURL,
          headers: { Authorization: `Bearer ${getters.fabricatorAdminToken}` },
          data: params,
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mutations: {
    setFabricatorAdminUser(state, user) {
      state.fabricatorAdminUser = user
    },
    setFabricatorAdminToken(state, token) {
      state.fabricatorAdminToken = token
    },
    setFabricatorAdminLoadedData(state, data) {
      state.fabricatorAdminLoadedData = data
    },
    setFabricatorAdminPendingCount(state, count) {
      state.fabricatorAdminPendingCount = count
    },
    setFabricatorAdminTaskCount(state, count) {
      state.fabricatorAdminTaskCount = count
    },
    setFabricatorAdminTargetTask(state, data) {
      state.fabricatorAdminTargetTask = data
    },
    setFabricatorAdminCategories(state, categories) {
      state.fabricatorAdminCategories = categories
    },
    setFabricatorAdminSelectedCategoryOption(state, option) {
      state.fabricatorAdminSelectedCategoryOption = option
    },
    setFabricatorAdminLaboratoryOptions(state, options) {
      state.fabricatorAdminLaboratoryOptions = options
    },
    setFabricatorAdminLaboratoryOption(state, { key, value }) {
      if (!state.fabricatorAdminLaboratoryOptions) {
        state.fabricatorAdminLaboratoryOptions = {}
      }

      state.fabricatorAdminLaboratoryOptions[key] = value
      window.localStorage.setItem(
        'fabricatorAdminLaboratoryOptions',
        JSON.stringify(state.fabricatorAdminLaboratoryOptions),
      )
    },
  },
}

export default fabricatorAdmin
