import { callWrappedApi } from '@/modules/axios'
import helpers from '@/helpers'

const crudTargets = [
  // 모델명 단수로 나열
  'country',
].map((key) => ({
  model: key,
  endpoint: helpers?.template.case.toSnake(helpers.template.case.pluralize(key)),
}))

const crud = {}
crudTargets.forEach(({ model, endpoint }) => {
  crud[model] = {
    create: (modelObj) => callWrappedApi({ method: 'post', url: `library/${endpoint}`, data: modelObj }),
    all: (params) => callWrappedApi({ method: 'get', url: `library/${endpoint}`, params }),
    detail: (modelId, params) => callWrappedApi({ method: 'get', url: `library/${endpoint}/${modelId}`, params }),
    update: (modelObj) => callWrappedApi({ method: 'put', url: `library/${endpoint}/${modelObj.id}`, data: modelObj }),
    delete: (modelId) => callWrappedApi({ method: 'delete', url: `library/${endpoint}/${modelId}` }),
  }
})

export default crud
