// atoms
import AppButtonV2 from '@/components/app/button/AppButtonV2'
import AppButtonV3 from '@/components/app/button/AppButtonV3'
import AppLinkButton from '@/components/app/button/AppLinkButton'
import AppLogo from '@/components/app/AppLogo'
import AppSkeleton from '@/components/app/AppSkeleton'
import AppWebpImg from '@/components/app/AppWebpImg'
import AppSlideNotice from '@/components/app/AppSlideNotice'
import AppTeleport from '@/components/app/AppTeleport'
import AppSpriteIcon from '@/components/app/AppSpriteIcon'
import AppBadge from '@/components/app/badge/AppBadge'

// molecules
import AppLoader from '@/components/app/AppLoader'
import AppStatusBadge from '@/components/app/AppStatusBadge'
import AppStatusIcon from '@/components/app/AppStatusIcon'
import AppTooltipIcon from '@/components/app/AppTooltipIcon'
import AppTooltipIconV2 from '@/components/app/AppTooltipIconV2'
import AppInput from '@/components/app/input/AppInput'
import AppInputV2 from '@/components/app/input/AppInputV2'
import AppSearchInput from '@/components/app/input/AppSearchInput'
import AppSelect from '@/components/app/select/AppSelect'
import AppMultiSelect from '@/components/app/select/AppMultiSelect'
import WrapperOverlayContents from '@/components/app/WrapperOverlayContents'

// app components
import AdaptiveLayout from '@/components/app/AdaptiveLayout'
import AppTooltip from '@/components/app/AppTooltip'
import AppPagination from '@/components/app/AppPagination'
import AppFoldableContents from '@/components/app/AppFoldableContents'
import WrapperDropdownOverlay from '@/components/app/WrapperDropdownOverlay'

import PageSidebarWrapper from '@/components/common/sidebar/PageSidebarWrapper'

const useGlobalComponents = (app) => {
  // atoms
  app
    .component('AppButtonV2', AppButtonV2)
    .component('AppButtonV3', AppButtonV3)
    .component('AppLinkButton', AppLinkButton)
    .component('AppLogo', AppLogo)
    .component('AppSkeleton', AppSkeleton)
    .component('AppWebpImg', AppWebpImg)
    .component('AppSlideNotice', AppSlideNotice)
    .component('AppTeleport', AppTeleport)
    .component('AppSpriteIcon', AppSpriteIcon)
    .component('AppBadge', AppBadge)

  // molecules
  app
    .component('AppLoader', AppLoader)
    .component('AppStatusBadge', AppStatusBadge)
    .component('AppStatusIcon', AppStatusIcon)
    .component('AppTooltipIcon', AppTooltipIcon)
    .component('AppTooltipIconV2', AppTooltipIconV2)
    .component('AppInput', AppInput)
    .component('AppInputV2', AppInputV2)
    .component('AppSearchInput', AppSearchInput)
    .component('AppSelect', AppSelect)
    .component('AppMultiSelect', AppMultiSelect)
    .component('WrapperOverlayContents', WrapperOverlayContents)

  // app components
  app
    .component('AdaptiveLayout', AdaptiveLayout)
    .component('AppTooltip', AppTooltip)
    .component('AppPagination', AppPagination)
    .component('AppFoldableContents', AppFoldableContents)
    .component('WrapperDropdownOverlay', WrapperDropdownOverlay)

  app.component('PageSidebarWrapper', PageSidebarWrapper)
}

export default useGlobalComponents
