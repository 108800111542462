<template>
  <a href="/" class="app-logo" :class="getClassName(clickable, logoSize)" @click.prevent="onClickLogo">
    <img :src="getLogoSrc(logoSize, dark)" alt="vmod fabric logo" />
  </a>
</template>

<script setup>
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  clickable: {
    type: Boolean,
    default: true,
  },
  dark: {
    type: Boolean,
    default: true,
  },
  logoSize: {
    type: String,
    default: 'large',
    validator: (val) => ['large', 'small'].indexOf(val) !== -1,
  },
})

const { plugins, store, router } = useGlobalHooks()

const onClickLogo = () => {
  if (!props.clickable) {
    return
  }

  router.push('/').then(() => {
    plugins.$helpers.dom.scrollToTop()
    store.commit('removeAllModals')
  })
}

const getClassName = (clickable, logoSize) => ({ clickable, 'small-size': logoSize === 'small' })

const getLogoSrc = (logoSize, dark) =>
  // eslint-disable-next-line import/no-dynamic-require, global-require
  require(`@/assets/images/${logoSize === 'small' ? 'small-' : ''}logo${dark ? '-black' : '-white'}.svg`)
</script>

<style lang="scss" scoped>
.app-logo {
  display: flex;
  align-items: center;
  cursor: default;

  img {
    width: 216px;
  }

  &.clickable {
    cursor: pointer;
  }

  &.small-size img {
    width: fit-content;
    height: 18px;
  }
}
</style>
