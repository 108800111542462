<template>
  <div v-if="isShowNotFound" class="view-not-found flex">
    <AppWebpImg class="background-image" fileName="vmod-library-404-bg" altFileFormat="jpg" />

    <span class="title">404</span>
    <span class="description">YOU'RE BEYOND THE BORDERS.</span>
    <button @click="$router.back()">REROUTE</button>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'
import { callWrappedApi } from '@/modules/axios'

const { store, router } = useGlobalHooks()

const isShowNotFound = ref(false)

const checkAvailableUrl = async () => {
  if (store.getters.isSSR) return

  const code = router.currentRoute.value.path.substring(1)
  if (!code) return

  try {
    const url = await callWrappedApi({ method: 'get', url: `library/short_urls?key=${code}` })

    if (url) {
      router.replace(url)
    }
  } catch (e) {
  } finally {
    isShowNotFound.value = true
  }
}

onMounted(checkAvailableUrl)
</script>

<style lang="scss" scoped>
.view-not-found {
  width: 100%;
  text-align: center;
  color: var(--white);
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .title {
    font-weight: 500;
    font-size: 240px;
    color: var(--white-70);
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(12px);
  }

  .description {
    font-weight: 300;
    font-size: 24px;
    letter-spacing: 0.1em;
    color: var(--black-60);
    margin-bottom: 30px;
  }

  button {
    width: 400px;
    padding: 16px 0;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.1em;
    color: var(--black);
    background: var(--white-30);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24), 0px 0px 12px rgba(255, 255, 255, 0.56),
      inset 2px 2px 0px rgba(255, 255, 255, 0.56);
    backdrop-filter: blur(30px);
    border-radius: 8px;
  }

  @media (max-width: 767px) {
    .title {
      max-width: calc(100% - 60px);
      font-size: 140px;
    }

    .description {
      max-width: calc(100% - 60px);
      font-size: 18px;
    }

    button {
      max-width: calc(100% - 60px);
      width: 100%;
    }
  }
}
</style>
