/* eslint-disable no-param-reassign */
import workspaceService from '@/services/workspace'

const workspace = {
  state: () => ({
    workspaces: null,
  }),
  getters: {
    workspaces: ({ workspaces }) => workspaces,
  },
  actions: {
    async loadWorkspaces({ commit }) {
      try {
        const workspaces = await workspaceService.workspaces()

        commit('setWorkspaces', workspaces)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    setCurrentWorkspaceIdHeader({ commit, getters, dispatch }, workspaceId) {
      if (!workspaceId) return

      commit('addHeader', { 'current-workspace-id': workspaceId })
      window.localStorage.setItem('header', JSON.stringify(getters.header))
      dispatch('setRequestHeader')
    },
    clearStateWhenChangeWorkspace({ commit }) {
      commit('setAuthSubscriptionPlans', null)
      commit('setAllDigitizationOrdersInfos', null)
    },
  },
  mutations: {
    setWorkspaces(state, workspaces) {
      state.workspaces = workspaces
    },
  },
}

export default workspace
