import cloDigitizationLanding from './clo-digitization-landing'

const pages = [cloDigitizationLanding]

export default pages.reduce((merged, page) => {
  Object.keys(page).forEach((key) => {
    // eslint-disable-next-line no-param-reassign
    merged[key] = page[key]
  })

  return merged
}, {})
