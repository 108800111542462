import { getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const useGlobalHooks = () => {
  const plugins = getCurrentInstance().appContext.config.globalProperties
  const store = useStore()
  const router = useRouter()

  return {
    store,
    router,
    plugins,
  }
}

export default useGlobalHooks
