/* eslint-disable no-param-reassign */
import collectionService from '@/services/collection'
import itemListService from '@/services/item-list'

const collection = {
  state: () => ({
    pinnedDetail: null,
    purchasedDetail: null,
  }),
  getters: {
    pinnedDetail: ({ pinnedDetail }) => pinnedDetail,
    purchasedDetail: ({ purchasedDetail }) => purchasedDetail,
  },
  actions: {
    async loadPinnedDetail({ commit }, { id, params }) {
      try {
        const data = await collectionService.detail(id, params)
        commit('setPinnedDetail', data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async loadPurchasedDetail({ commit }, query) {
      try {
        const data = await itemListService.purchasedItems.all(query)

        commit('setPurchasedDetail', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mutations: {
    setPinnedDetail(state, newPinnedDetail) {
      state.pinnedDetail = newPinnedDetail
    },
    setPurchasedDetail(state, newPurchasedDetail) {
      state.purchasedDetail = newPurchasedDetail
    },
  },
}

export default collection
