/* eslint-disable no-param-reassign */
import itemListService from '@/services/item-list'

const addMoreInfoInItems = async (data, itemType) => {
  const itemIds = data.map(({ id }) => id).join(',')

  const [isPinnedList, isPurchasedList] = await Promise.all([
    itemListService.pinnedItems.isPinned({
      itemIds,
      itemType,
    }),
    itemListService.purchasedItems.isPurchased({
      itemIds,
      itemType,
    }),
  ])

  return data.map((item) => ({ ...item, isPinned: isPinnedList[item.id], isPurchased: isPurchasedList[item.id] }))
}

const itemList = {
  state: () => ({
    textureItems: null,
    textureItemsCount: null,
    twinFabricItems: null,
    twinFabricItemsCount: null,
    drapeTemplateItems: null,
    drapeTemplateItemsCount: null,
    filtersCount: null,
    itemThumbnailMode: 'sphere',
    totalItemsCount: null,
    filters: null,
  }),
  getters: {
    textureItems: ({ textureItems }) => textureItems,
    textureItemsCount: ({ textureItemsCount }) => textureItemsCount,
    twinFabricItems: ({ twinFabricItems }) => twinFabricItems,
    twinFabricItemsCount: ({ twinFabricItemsCount }) => twinFabricItemsCount,
    drapeTemplateItems: ({ drapeTemplateItems }) => drapeTemplateItems,
    drapeTemplateItemsCount: ({ drapeTemplateItemsCount }) => drapeTemplateItemsCount,
    filtersCount: ({ filtersCount }) => filtersCount,
    itemThumbnailMode: ({ itemThumbnailMode }) => itemThumbnailMode,
    totalItemsCount: ({ totalItemsCount }) => totalItemsCount,
    filters: ({ filters }) => filters,
  },
  actions: {
    async loadTextureItems({ commit, getters }, query) {
      try {
        const [{ data, count }, filtersCount] = await Promise.all([
          itemListService.textures.all(query),
          itemListService.textures.count(query),
        ])
        commit('setTextureItemsCount', count)
        commit('setFiltersCount', filtersCount)

        if (!getters.me || data.length === 0) {
          commit('setTextureItems', data)
          return
        }

        const refinedData = await addMoreInfoInItems(data, 'TexturePreset')
        commit('setTextureItems', refinedData)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async loadTwinFabricItems({ commit, getters }, query) {
      try {
        const [{ data, count }, filtersCount] = await Promise.all([
          itemListService.twinFabrics.all(query),
          itemListService.twinFabrics.count(query),
        ])
        commit('setTwinFabricItemsCount', count)
        commit('setFiltersCount', filtersCount)

        if (!getters.me || data.length === 0) {
          commit('setTwinFabricItems', data)
          return
        }

        const refinedData = await addMoreInfoInItems(data, 'Preset')
        commit('setTwinFabricItems', refinedData)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async loadDrapeTemplateItems({ commit, getters }, query) {
      try {
        const [{ data, count }, filtersCount] = await Promise.all([
          itemListService.drapeTemplates.all(query),
          itemListService.drapeTemplates.count(query),
        ])
        commit('setDrapeTemplateItemsCount', count)
        commit('setFiltersCount', filtersCount)

        if (!getters.me || data.length === 0) {
          commit('setDrapeTemplateItems', data)
          return
        }

        const refinedData = await addMoreInfoInItems(data, 'DrapeTemplate')
        commit('setDrapeTemplateItems', refinedData)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async loadTotalItemsCount({ commit, getters }, isForced) {
      if (!isForced && getters.totalItemsCount) return

      try {
        const data = await itemListService.count()

        commit('setTotalItemsCount', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    async loadFilters({ commit }, itemType) {
      try {
        const data = await itemListService.filters(itemType)
        const { pattern } = data

        if (pattern.filters.length === 0) {
          commit('setFilters', data)
        } else {
          commit('setFilters', {
            ...data,
            pattern: {
              name: pattern.name,
              filters: [
                {
                  type: 'Pattern',
                  id: '-1',
                  name: 'Solid',
                  sequence: 1,
                },
                {
                  type: 'Pattern',
                  id: '-2',
                  name: 'Pattern',
                  sequence: 1,
                },
                ...pattern.filters.map((patternOption) => {
                  if (patternOption.name === 'Solid') {
                    return {
                      ...patternOption,
                      parentId: '-1',
                    }
                  }

                  return { ...patternOption, parentId: '-2' }
                }),
              ],
            },
          })
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
  mutations: {
    setTextureItems(state, items) {
      state.textureItems = items
    },
    setTextureItemsCount(state, count) {
      state.textureItemsCount = count
    },
    setTwinFabricItems(state, items) {
      state.twinFabricItems = items
    },
    setTwinFabricItemsCount(state, count) {
      state.twinFabricItemsCount = count
    },
    setDrapeTemplateItems(state, items) {
      state.drapeTemplateItems = items
    },
    setDrapeTemplateItemsCount(state, count) {
      state.drapeTemplateItemsCount = count
    },
    setFiltersCount(state, filtersCount) {
      state.filtersCount = filtersCount
    },
    setItemThumbnailMode(state, thumbnailMode) {
      state.itemThumbnailMode = thumbnailMode
    },
    setTotalItemsCount(state, itemsCount) {
      state.totalItemsCount = itemsCount
    },
    setFilters(state, filters) {
      state.filters = filters
    },
  },
}

export default itemList
