<template>
  <div class="wrapper-dropdown-overlay" @mousedown="slideLeave">
    <transition name="slide-down">
      <div v-show="prepared" ref="tryToGetMyDirectChildDOM" class="p-absolute" :style="{ top, bottom, left, right }">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup>
/**
 * 드롭다운이 열리면, 드롭다운 이외의 영역은 UX가 블록되는것이 일반적으로 기대되는 UX이다.
 * 이 컴포넌트는 클릭시 드롭다운을 꺼주는 백드롭 및 슬라이드 애니메이션을 제공한다.
 *
 * 사용법:)
 * <WrapperDropdownOverlay
 *   v-if="showDropdown"
 *   :mountBelow="domYouWantToUseAsToggleButton">
 *   ANY CONTENT FOR <slot/>
 * </WrapperDropdownOverlay>
 */
import { onMounted, onUnmounted, ref, watch } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const emit = defineEmits(['close'])

const props = defineProps({
  mountBelow: {
    type: Object,
    required: true,
  },
  align: {
    type: String,
    default: 'left',
    validator: (val) => ['left', 'center', 'right', 'full-screen'].indexOf(val) !== -1,
  },
  forcedBottom: Boolean,
  contentsWidth: {
    type: Number,
    default: 0,
  },
  isUseResizeClose: {
    type: Boolean,
    default: true,
  },
})

const { plugins, store } = useGlobalHooks()

/**
 * slide-enter: modelValue = true가 되면서 wrapper-dropdown-overlay가 즉시 펼쳐진 뒤, prepared가 뒤이어 true가 되며 이루어짐
 * slide-leave: 먼저 prepared를 false로 하며 약 0.2초간 slide-leave 애니메이션을 기다린 '후에' 비로소 modelValue = false로 하여 wrapper-dropdown-overlay를 감춘다.
 * */
const prepared = ref(null)

const top = ref(null)
const bottom = ref(null)
const left = ref(null)
const right = ref(null)

const tryToGetMyDirectChildDOM = ref(null)

const slotDOM = ref(null)

const slideEnter = () => {
  if (!props.mountBelow) return

  // eslint-disable-next-line prefer-destructuring
  slotDOM.value = tryToGetMyDirectChildDOM.value.children[0]

  if (!slotDOM.value) {
    prepared.value = true
    return
  }

  const rectMountBelow = props.mountBelow.getBoundingClientRect()

  if (props.align === 'left') {
    // 오른쪽 창 끝까지의 거리가 모자르다면, 현재 보이는 영역을 넘어가지 않도록 left값을 설정해준다.
    const distanceHor = window.innerWidth - rectMountBelow.x

    if (distanceHor - props.contentsWidth < 0) {
      left.value = `${window.innerWidth - props.contentsWidth - 16}px` // 16px은 스크롤 너비
    } else {
      left.value = `${rectMountBelow.x}px`
    }
  }

  if (props.align === 'right') {
    right.value = `${window.innerWidth - (rectMountBelow.x + rectMountBelow.width)}px`
  }

  if (props.align === 'center') {
    left.value = `${rectMountBelow.x + rectMountBelow.width / 2 - slotDOM.value.clientWidth / 2}px`
  }

  if (props.align === 'full-screen') {
    left.value = '0px'
  }

  if (
    slotDOM.value.clientHeight + rectMountBelow.y + rectMountBelow.height > window.innerHeight ||
    props.forcedBottom
  ) {
    bottom.value = 0
  } else {
    top.value = `${rectMountBelow.y + rectMountBelow.height}px`
  }

  prepared.value = true
}

const slideLeave = (e) => {
  if (!e || (e.type !== 'resize' && !e.target.classList.contains('wrapper-dropdown-overlay'))) return

  slideClose()
}

const slideClose = async () => {
  prepared.value = false
  await plugins.$helpers.sleep(200)
  emit('close')
}

const onScroll = () => {
  slideEnter()
}

watch(() => store.getters.scrollTop, onScroll)

onMounted(() => {
  if (process.env.VUE_APP_SSR) return

  slideEnter()
  if (props.isUseResizeClose) window.addEventListener('resize', slideLeave, { capture: true })
})

onUnmounted(() => {
  if (process.env.VUE_APP_SSR || !props.isUseResizeClose) return

  window.removeEventListener('resize', slideLeave)
})

defineExpose({ slideClose })
</script>

<style lang="scss" scoped>
.wrapper-dropdown-overlay {
  z-index: 3;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .slide-down-enter-active,
  .slide-down-leave-active {
    transition: all 0.3s ease;
  }

  .slide-down-enter-from,
  .slide-down-leave-to {
    opacity: 0;
    transform: translateY(-40px);
  }
}
</style>
