<template>
  <div class="app-sprite-icon" :style="style" />
</template>

<script setup>
import { computed } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  spliteImageName: {
    type: String,
    default: 'vfab-splite-icons.png',
  },
  iconPosition: {
    type: Array,
    default: () => [0, 0],
  },
  iconSize: {
    type: Number,
    default: 16,
  },
})

const { plugins } = useGlobalHooks()

const style = computed(() => {
  const { spliteImageName, iconPosition, iconSize } = props
  const spliteImageUrl = plugins.$helpers.withCdn(spliteImageName, 'images/uploads')

  return {
    width: `${iconSize}px`,
    height: `${iconSize}px`,
    backgroundImage: `url(${spliteImageUrl})`,
    backgroundPosition: `${iconPosition[0] * -iconSize}px ${iconPosition[1] * -iconSize}px`,
    backgroundSize: '300%',
  }
})
</script>

<style lang="scss" scoped>
.app-sprite-icon {
  display: flex;
  aspect-ratio: 1 / 1;
}
</style>
