import { store as $store } from '@/store'
import cdn from './cdn'

const sanitize = (html) => {
  const regex = /(&nbsp;|<([^>]+)>)/gi
  return (html || '').replace(regex, '')
}

const defaults = {
  title: 'VMOD 3D Library: Customizable, hyper-realistic digital fabrics',
  description:
    'VMOD 3D Library is the world’s largest digital fabric library with thousands of hyper-realistic, customizable 3D materials created from real life twin fabrics.',
  image: 'vfab-default-og-image.jpg',
}

const removeExisting = (id) => {
  if (process.env.VUE_APP_SSR) return

  const existingDom = document.getElementById(id)
  if (existingDom) document.head.removeChild(existingDom)
}

const appendMetaTags = (tags) => {
  tags.forEach((tag) => {
    $store.commit('appendMetaTags', tag)

    if (process.env.VUE_APP_SSR) return

    removeExisting(tag.id)
    const tagDOM = document.createElement(tag.tag || 'meta')
    Object.keys(tag).forEach((key) => tagDOM.setAttribute(key, tag[key]))
    document.head.appendChild(tagDOM)
  })
}

const meta = {
  defaults,
  seo: ({ title, description, image, canonicalLink }) => {
    if (title) meta.setDocumentTitle(title)
    if (description) meta.renderDescription(description)
    if (image) meta.renderOgImage(image)
    if (canonicalLink) meta.renderCanonicalLink(canonicalLink)
  },
  setDocumentTitle: (title) => {
    const content = sanitize(title || defaults.title)

    if (!process.env.VUE_APP_SSR) document.title = content

    appendMetaTags(
      [
        { id: 'meta-og-title', property: 'og:title', content },
        { id: 'meta-twitter-title', property: 'twitter:title', content },
      ],
      title,
    )
  },
  renderDescription: (description) => {
    const content = sanitize(description || defaults.description)

    appendMetaTags([
      { id: 'meta-description', name: 'description', content },
      { id: 'meta-og-description', property: 'og:description', content },
      { id: 'meta-twitter-description', property: 'twitter:description', content },
    ])
  },
  renderOgImage: (image) => {
    const content = cdn.withCdn(image || defaults.image, 'images/large')
    appendMetaTags([
      { id: 'meta-og-image', property: 'og:image', content },
      { id: 'meta-twitter-image', property: 'twitter:image', content },
    ])
  },
  renderOgType: (content) => {
    appendMetaTags([{ id: 'meta-og-type', property: 'og:type', content }])
  },
  renderCanonicalLink: (canonicalLink) => {
    const content = `https://vmod.xyz${canonicalLink}`
    appendMetaTags([
      { id: 'meta-og-url', property: 'og:url', content },
      { id: 'meta-twitter-url', property: 'twitter:url', content },
      { id: 'link-rel-canonical', rel: 'canonical', href: content, tag: 'link' },
    ])
  },
}

export default meta
