<template>
  <div class="app-input-v2" :class="refinedClassName">
    <i class="input-icon left-icon" :class="inputIcon" />

    <input
      ref="refInput"
      v-model="inputValue"
      :type="refinedInputType"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="onInputValue"
      @keydown="onKeydownValue"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />

    <i
      v-if="refinedIsShowClearButton"
      class="input-icon right-icon clickable fa fa-circle-xmark"
      @click="onClickClear()"
    />

    <i
      v-if="inputType === 'password'"
      class="input-icon right-icon clickable fa"
      :class="`fa-eye${isShowPassword ? '' : '-slash'}`"
      @click="onClickShowPasswordInput()"
    />

    <span class="input-notice">{{ notice.text }}</span>
  </div>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'
import { KEYS } from '@/assets/constants'

const emit = defineEmits(['update:modelValue', 'key-down-enter', 'set-notice', 'click-clear'])

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  inputType: {
    type: String,
    default: 'text',
  },
  numberOptions: {
    type: Object,
    default: () => ({}),
  },
  placeholder: {
    type: String,
    default: '',
  },
  notice: {
    type: Object,
    default: () => ({}),
  },
  inputIcon: {
    type: String,
    default: '',
  },
  isShowClearButton: Boolean,
  disabled: Boolean,
})

const { plugins, store } = useGlobalHooks()

const inputValue = ref('')

const validateNumberInput = (targetValue) => {
  const { modelValue, numberOptions } = props
  const { max } = numberOptions

  const blockInput = () => {
    inputValue.value = modelValue
  }

  if (!plugins.$helpers.regex.number.test(targetValue)) {
    blockInput()
    return
  }

  if (max && targetValue > max) {
    blockInput()
    return
  }

  emit('update:modelValue', targetValue)
}

const refinedInputType = computed(() => {
  if (props.inputType === 'number') return 'text'

  if (props.inputType === 'password') return isShowPassword.value ? 'text' : 'password'

  return props.inputType
})

const onInputValue = (e) => {
  if (props.inputType !== 'number') {
    emit('update:modelValue', e.target.value)
  }

  if (props.inputType === 'number') {
    validateNumberInput(e.target.value)
  }

  if (isShowNotice.value) hideNotice()
}

const onKeydownValue = ({ key }) => {
  if (key === KEYS.ENTER) {
    emit('key-down-enter')
  }
}

const isShowNotice = ref(false)

const hideNotice = async () => {
  isShowNotice.value = false

  await plugins.$helpers.sleep(400)

  if (!isShowNotice.value) {
    emit('set-notice', {})
    return
  }

  isShowNotice.value = true
}

watch(
  () => props.notice,
  () => {
    if (props.notice.text) {
      isShowNotice.value = true
    }
  },
)

const syncInputValue = () => {
  if (props.modelValue !== inputValue.value) {
    inputValue.value = props.modelValue
  }
}

watch(() => props.modelValue, syncInputValue)

onMounted(syncInputValue)

const onClickClear = () => {
  inputValue.value = ''
  onInputValue({ target: { value: '' } })
  emit('click-clear')
}

onUnmounted(onClickClear)

const refinedIsShowClearButton = computed(() => props.isShowClearButton && inputValue.value)

const isFocused = ref(false)

const refInput = ref()

const focusInput = () => {
  refInput.value.focus()
}

const blurInput = () => {
  refInput.value.blur()
}

defineExpose({ blurInput, focusInput, isFocused })

const isShowPassword = ref(false)
const onClickShowPasswordInput = () => {
  isShowPassword.value = !isShowPassword.value
}

const refinedClassName = computed(() => {
  const { inputIcon, isShowClearButton, inputType, disabled } = props

  return {
    'show-notice': isShowNotice.value,
    'show-icon': inputIcon,
    'show-right-icon-button': isShowClearButton || inputType === 'password',
    focused: isFocused.value,
    disabled,
    [store.getters.headerTheme]: true,
  }
})
</script>

<style lang="scss" scoped>
.app-input-v2 {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  height: 48px;
  border: 1px solid transparent;
  border-radius: 8px;
  margin-bottom: 0;
  transition: margin 0.4s, border-color 0.4s, background-color 0.4s;

  .input-icon {
    position: absolute;
    font-size: 16px;

    &.left-icon {
      left: 16px;
    }

    &.right-icon {
      right: 16px;
    }

    &.clickable {
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    height: 100%;
    font-weight: 400;
    font-size: 16px;
    padding: 0 16px;
    background-color: transparent;
    border: none;

    &:disabled {
      opacity: 1;
    }

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  .input-notice {
    font-weight: 400;
    font-size: 12px;
    z-index: -1;
    position: absolute;
    left: 0;
    bottom: 0;
    color: var(--warning-red);
    opacity: 0;
    transition: transform 0.4s, opacity 0.4s;
  }

  &.show-notice {
    margin-bottom: 26px;

    .input-notice {
      transform: translateY(calc(100% + 8px));
      opacity: 1;
    }
  }

  &.show-icon input {
    padding-left: 52px;
  }

  &.show-right-icon-button input {
    padding-right: 52px;
  }

  &.dark {
    background-color: var(--white-10);

    .input-icon {
      color: var(--gs-af);
    }

    input {
      color: var(--white);

      &::placeholder {
        color: var(--white-30);
      }

      &:disabled {
        color: var(--gs-af);
        -webkit-text-fill-color: var(--gs-af);
      }
    }

    .clear-button {
      color: var(--gs-af);
    }

    .show-password-icon {
      color: var(--white-70);
    }
  }

  &.light {
    background-color: var(--white);

    .input-icon {
      color: var(--white-90);
    }

    input {
      color: var(--gs-21);

      &:disabled {
        color: var(--gs-af);
        -webkit-text-fill-color: var(--gs-af);
      }
    }

    .clear-button {
      color: var(--gs-af);
    }

    .show-password-icon {
      color: var(--white-70);
    }
  }

  @media (max-width: 767px) {
    height: 40px;

    .input-icon {
      font-size: 14px;
    }

    input {
      font-size: 14px;
    }

    &.show-icon input {
      padding-left: 48px;
    }

    &.show-right-icon-button input {
      padding-right: 48px;
    }
  }
}
</style>
