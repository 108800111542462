import { callWrappedApi } from '@/modules/axios'

const workspaceService = {
  members: {
    list: (params) => callWrappedApi({ method: 'get', url: 'library/members', params }),
    count: () => callWrappedApi({ method: 'get', url: 'library/members/count' }),
    invite: (data) => callWrappedApi({ method: 'post', url: 'library/workspaces/invite_members', data }),
    reinvite: ({ id }) => callWrappedApi({ method: 'post', url: 'library/members/reinvite', data: { id } }),
    remove: ({ id }) => callWrappedApi({ method: 'delete', url: `library/members/${id}/remove` }),
    inactive: ({ id }) => callWrappedApi({ method: 'put', url: `library/members/${id}/deactivate` }),
    reactivate: ({ id }) => callWrappedApi({ method: 'put', url: `library/members/${id}/reactivate` }),
    accessLevel: (id, accessLevel) =>
      callWrappedApi({ method: 'put', url: `/library/members/${id}/access_level`, data: { accessLevel } }),
  },
  verify: (token) => callWrappedApi({ method: 'get', url: 'library/members/verify', params: { token } }),
  showChooseWorkspace: () => callWrappedApi({ method: 'get', url: 'library/customers/show_choose_workspace' }),
  workspaces: () => callWrappedApi({ method: 'get', url: 'library/members/all_workspaces' }),
  count: () => callWrappedApi({ method: 'get', url: 'library/customers/workspace_count' }),
  editInfo: ({ id, info }) => callWrappedApi({ method: 'put', url: `library/members/${id}/edit_member`, data: info }),
  delete: (id) => callWrappedApi({ method: 'delete', url: `library/workspaces/${id}` }),
}

export default workspaceService
