const regex = {
  email:
    /^[a-zA-Z0-9.!$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
  url: /\S\.\S/,
  number: /^[0-9]*$/,
  notEmpty: /\S*$/,
  isEmpty: /\s/,
}

export default regex
