import useGlobalHooks from '@/hooks/global-hooks'

const useLocalStorage = () => {
  const { plugins, store, router } = useGlobalHooks()

  const compareStoreHeaderAndLocalHeader = (storeHeader = {}, localHeader = {}) => {
    const compareResult = []

    Object.entries(storeHeader || {}).forEach(([key, val]) => {
      compareResult.push({ key, storeValue: val, type: 'delete' })
    })

    Object.entries(localHeader || {}).forEach(([key, val]) => {
      const index = compareResult.findIndex((item) => item.key === key)

      if (index === -1) {
        compareResult.push({ type: 'add', key, newValue: val })
        return
      }

      if (compareResult[index].storeValue === val) {
        compareResult[index].type = 'equal'
        return
      }

      compareResult[index].type = 'replace'
      compareResult[index].newValue = val
    })

    return compareResult.filter(({ type }) => type !== 'equal')
  }

  const showWarningModal = () => {
    plugins.$modal
      .basicV2({
        titleIcon: 'success-v2',
        body: 'Workspace change detected on another tab.<br/>Please press the button to continue.',
        buttons: [{ buttonText: 'Confirm' }],
        preventCloseOnClickBackdrop: true,
      })
      .then(() => {
        window.location.reload()
      })
  }

  const routerCheck = () => {
    const routeNames = ['ViewSignIn', 'ViewSignInInputPassword', 'ViewSignUp', 'ViewSignUpSocial']

    if (!store.getters.me) return

    if (routeNames.includes(router.currentRoute.value.name)) {
      router.push('/')
    }
  }

  const checkHeaderLocalStorage = async () => {
    const { header } = store.getters
    const localHeader = JSON.parse(window.localStorage.getItem('header') || '""')

    const compareResult = compareStoreHeaderAndLocalHeader(header, localHeader)

    const tokenCompareResult = compareResult.find(({ key }) => key === 'token') || {}

    if (tokenCompareResult.type === 'delete') {
      store.dispatch('removeHeader')
      store.commit('initAppData')
      store.commit('setHeaderTheme', 'dark')
      store.dispatch('bootstrap', 'no-auth')
      router.push('/')
      return
    }

    if (tokenCompareResult.type === 'add') {
      store.commit('setLoading', { isShow: true })
      await store.dispatch('loadAuthToken')
      await store.dispatch('loadMe')
      routerCheck()
      store.commit('setLoading', { isShow: false })
      return
    }

    const workspaceIdCompareResult = compareResult.find(({ key }) => key === 'current-workspace-id') || {}

    if (workspaceIdCompareResult.type === 'add') {
      store.commit('addHeader', { 'current-workspace-id': workspaceIdCompareResult.newValue })
      store.dispatch('setRequestHeader')
      return
    }

    if (workspaceIdCompareResult.type !== 'replace') return

    const { path, params } = router.currentRoute.value

    if (
      [
        `/fabricator/${params.id}`,
        `/fabricator/${params.id}/quick-generate`,
        `/fabricator/${params.id}/generate-swatches`,
        `/fabricator/${params.id}/generate-swatches-with-seed`,
        `/fabricator/${params.id}/upload-swatch`,
        `/fabricator/${params.id}/make-seamless`,
        `/fabricator/${params.id}/upscale-texture`,
        `/fabricator/${params.id}/generate-pbr-maps`,
      ].includes(path)
    ) {
      router.push('/fabricator-list').then(showWarningModal)
      return
    }

    showWarningModal()
  }

  const checkMetaLocalStorage = async () => {
    if (router.currentRoute.value.name === 'ViewSurvey' && plugins.$helpers.localStorage.getMeta('isSurveySubmitted')) {
      await store.dispatch('loadMe')
      router.push('/')
    }
  }

  const checkLocalStorage = ({ key }) => {
    if (key === 'header') checkHeaderLocalStorage()

    if (key === 'meta') checkMetaLocalStorage()
  }

  return { checkLocalStorage }
}

export default useLocalStorage
