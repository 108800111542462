/* eslint-disable no-param-reassign */
import fabricatorService from '@/services/fabricator'
import itemListService from '@/services/item-list'
import { router } from '@/router'

const fabricator = {
  state: () => ({
    fabricatorTextureGeneration: null,
    fabricatorCurrentVersionHistories: null,
    fabricatorVersionHistoryList: null,
    fabricatorCurrentLeftTab: 'generate-swatches',
    fabricatorCurrentRightTab: '',
    fabricatorShowMobileLeftTab: false,
    fabricatorShowMobileRightTab: false,
    fabricatorAvailableLeftTabs: ['generate-swatches'],
    fabricatorIsStopped: false,
    fabricatorControl2DOptions: {
      tileTexture: false,
      gridLines: false,
    },
    fabricatorViewModeImage: null,
    fabricatorContentsType: null,
    fabricatorGuideType: 'none',
    fabricatorIsShowThdViewerControlNotice: false,
    fabricatorFilters: null,
    fabricatorPrevFullPath: null,
    fabricatorThdViewerOptions: {},
    fabricatorInputClearTrigger: false,
    fabricatorEstimatedProcessInfos: {},
    fabricatorVersionHistorySelectedImage: null,
    fabricatorFocusVersionHistoryTrigger: { title: false, download: false },
    fabricatorNumberOfOutputsHover: 0,
    fabricatorCurrentProgressQueueInfo: null,
    fabricatorTipContentsType: 'none',
    fabricatorImageCroppedOptions: '',
  }),
  getters: {
    fabricatorTextureGeneration: ({ fabricatorTextureGeneration }) => fabricatorTextureGeneration,
    fabricatorVersionHistoryList: ({ fabricatorVersionHistoryList }) => fabricatorVersionHistoryList,
    fabricatorCurrentVersionHistories: ({ fabricatorCurrentVersionHistories }) => fabricatorCurrentVersionHistories,
    fabricatorCurrentLeftTab: ({ fabricatorCurrentLeftTab }) => fabricatorCurrentLeftTab,
    fabricatorCurrentRightTab: ({ fabricatorCurrentRightTab }) => fabricatorCurrentRightTab,
    fabricatorShowMobileLeftTab: ({ fabricatorShowMobileLeftTab }) => fabricatorShowMobileLeftTab,
    fabricatorShowMobileRightTab: ({ fabricatorShowMobileRightTab }) => fabricatorShowMobileRightTab,
    fabricatorAvailableLeftTabs: ({ fabricatorAvailableLeftTabs }) => fabricatorAvailableLeftTabs,
    fabricatorIsStopped: ({ fabricatorIsStopped }) => fabricatorIsStopped,
    fabricatorControl2DOptions: ({ fabricatorControl2DOptions }) => fabricatorControl2DOptions,
    fabricatorViewModeImage: ({ fabricatorViewModeImage }) => fabricatorViewModeImage,
    fabricatorContentsType: ({ fabricatorContentsType }) => fabricatorContentsType,
    fabricatorGuideType: ({ fabricatorGuideType }) => fabricatorGuideType,
    fabricatorIsShowThdViewerControlNotice: ({ fabricatorIsShowThdViewerControlNotice }) =>
      fabricatorIsShowThdViewerControlNotice,
    fabricatorFilters: ({ fabricatorFilters }) => fabricatorFilters,
    fabricatorPrevFullPath: ({ fabricatorPrevFullPath }) => fabricatorPrevFullPath,
    fabricatorThdViewerOptions: ({ fabricatorThdViewerOptions }) => fabricatorThdViewerOptions,
    fabricatorInputClearTrigger: ({ fabricatorInputClearTrigger }) => fabricatorInputClearTrigger,
    fabricatorEstimatedProcessInfos: ({ fabricatorEstimatedProcessInfos }) => fabricatorEstimatedProcessInfos,
    fabricatorVersionHistorySelectedImage: ({ fabricatorVersionHistorySelectedImage }) =>
      fabricatorVersionHistorySelectedImage,
    fabricatorFocusVersionHistoryTrigger: ({ fabricatorFocusVersionHistoryTrigger }) =>
      fabricatorFocusVersionHistoryTrigger,
    fabricatorNumberOfOutputsHover: ({ fabricatorNumberOfOutputsHover }) => fabricatorNumberOfOutputsHover,
    fabricatorCurrentProgressQueueInfo: ({ fabricatorCurrentProgressQueueInfo }) => fabricatorCurrentProgressQueueInfo,
    fabricatorTipContentsType: ({ fabricatorTipContentsType }) => fabricatorTipContentsType,
    fabricatorImageCroppedOptions: ({ fabricatorImageCroppedOptions }) => fabricatorImageCroppedOptions,
  },
  actions: {
    loadFabricatorTextureGeneration: async ({ commit, getters }) => {
      try {
        const { id: sharingKey } = router.currentRoute.value.params

        const data = await fabricatorService.textureGeneration.detail(sharingKey)

        const isPinned = await itemListService.pinnedItems.isPinned({
          itemIds: data.id,
          itemType: data.type,
        })

        data.isPinned = isPinned[data.id]

        commit('setFabricatorCurrentVersionHistories', data.currentHistories)
        delete data.currentHistories
        commit('setFabricatorTextureGeneration', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    loadFabricatorVersionHistoryList: async ({ commit, getters }, offset = 0) => {
      try {
        const { id: sharingKey } = router.currentRoute.value.params

        const { data, hasMore, count } = await fabricatorService.versionHistory.list({
          sharingKey,
          offset,
          limit: 100,
        })

        if (offset === 0) {
          commit('setFabricatorVersionHistoryList', { data, hasMore, count, offset })
          return
        }

        const { data: prevData } = getters.fabricatorVersionHistoryList
        commit('setFabricatorVersionHistoryList', { data: [...prevData, ...data], hasMore, count, offset })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    loadFabricatorFilters: async ({ commit }, itemType) => {
      try {
        const data = await fabricatorService.filters(itemType)

        commit('setFabricatorFilters', data)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    processTextureGeneration: async ({ commit }, params) => {
      try {
        const refinedParams = Object.fromEntries(
          Object.entries(params).map(([key, value]) => {
            if (key.endsWith('Id')) return [key, parseInt(value, 10)]

            return [key, value]
          }),
        )

        const { sharingKey, code, status, versionHistoryId, previousVersionHistoryId } =
          await fabricatorService.textureGeneration.process(refinedParams)

        commit('addFabricatorTextureGeneration', {
          sharingKey,
          previousVersionHistoryId: `${previousVersionHistoryId}`,
          currentVersionHistoryId: `${versionHistoryId}`,
          code,
          status,
        })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    requestVersionHistoryFile: async ({ commit }, id) => {
      try {
        await fabricatorService.versionHistory.requestFile(id)

        commit('setFabricatorVersionHistoryListDetail', { id, queries: { fileStatus: 'creating' } })
      } catch (error) {
        return Promise.reject(error)
      }
    },
    getEstimatedProcessInfos: async ({ commit, getters }, step) => {
      try {
        if (getters.fabricatorEstimatedProcessInfos[step]) return

        commit('setFabricatorEstimatedProcessInfos', { step })
        const data = await fabricatorService.versionHistory.processInfos(step)
        commit('setFabricatorEstimatedProcessInfos', { step, value: data })
      } catch (error) {}
    },
  },
  mutations: {
    setFabricatorTextureGeneration: (state, data) => {
      state.fabricatorTextureGeneration = data
    },
    addFabricatorTextureGeneration: (state, queries) => {
      const targetValue = state.fabricatorTextureGeneration ? { ...state.fabricatorTextureGeneration } : {}

      Object.entries(queries).forEach(([key, value]) => {
        targetValue[key] = value
      })

      state.fabricatorTextureGeneration = targetValue
    },
    setFabricatorCurrentVersionHistories: (state, histories) => {
      state.fabricatorCurrentVersionHistories = histories
    },
    setFabricatorCurrentVersionHistory: (state, { key, value }) => {
      if (!state.fabricatorCurrentVersionHistories) {
        state.fabricatorCurrentVersionHistories = {}
      }

      state.fabricatorCurrentVersionHistories[key] = value
    },
    setFabricatorVersionHistoryList: (state, list) => {
      state.fabricatorVersionHistoryList = list
    },
    setFabricatorVersionHistoryListDetail: (state, { id, queries }) => {
      const { data } = state.fabricatorVersionHistoryList

      state.fabricatorVersionHistoryList = {
        ...state.fabricatorVersionHistoryList,
        data: data.map((item) => {
          if (item.id === id) {
            return { ...item, ...queries }
          }
          return item
        }),
      }
    },
    setFabricatorCurrentLeftTab: (state, tab) => {
      state.fabricatorCurrentLeftTab = tab
    },
    setFabricatorCurrentRightTab: (state, tab) => {
      state.fabricatorCurrentRightTab = tab
    },
    setFabricatorShowMobileLeftTab: (state, show) => {
      state.fabricatorShowMobileLeftTab = show
    },
    setFabricatorShowMobileRightTab: (state, show) => {
      state.fabricatorShowMobileRightTab = show
    },
    setFabricatorAvailableLeftTabs: (state, tabs) => {
      state.fabricatorAvailableLeftTabs = tabs
    },
    setFabricatorIsStopped: (state, isStopped) => {
      state.fabricatorIsStopped = isStopped
    },
    setClearFabricatorControl2DOptions: (state) => {
      state.fabricatorControl2DOptions = {
        tileTexture: false,
        gridLines: false,
      }
    },
    setFabricatorControl2DOption: (state, { key, value }) => {
      state.fabricatorControl2DOptions[key] = value
    },
    setFabricatorViewModeImage: (state, image) => {
      state.fabricatorViewModeImage = image
    },
    setFabricatorContentsType: (state, type) => {
      state.fabricatorContentsType = type
    },
    setFabricatorGuideType: (state, type) => {
      state.fabricatorGuideType = type
    },
    setFabricatorIsShowThdViewerControlNotice: (state, isShow) => {
      state.fabricatorIsShowThdViewerControlNotice = isShow
    },
    setFabricatorFilters: (state, filters) => {
      state.fabricatorFilters = filters
    },
    setFabricatorPrevFullPath: (state, fullpath) => {
      state.fabricatorPrevFullPath = fullpath
    },
    setFabricatorThdViewerOptions: (state, options) => {
      state.fabricatorThdViewerOptions = options
    },
    clearFabricatorThdViewerOptions: (state) => {
      state.fabricatorThdViewerOptions = {
        objectName: 'shader-ball',
        objectAutoRotate: false,
        environmentAutoRotate: false,
        lightingHDRIBackground: 'Indoor',
        lightingEnvMapIntensity: 0.45,
        lightingIsShowBackground: false,
        textureScaleSize: 2.5,
        textureRoughness: 1,
        textureMetalness: 1,
        textureNormalScaleSize: 2.5,
        textureNormalScaleSizeInverted: false,
        textureDisplacementScale: 0,
        textureDisplacementScaleInverted: false,
        shaderSheen: 0,
        shaderSheenRoughness: 0,
        shaderSheenColorHEX: '#ffffff',
        shaderIridescence: 0,
        shaderThinFilm: 1.8,
        colorsBaseMapColors: [],
      }
    },
    setFabricatorThdViewerOption: (state, { key, option }) => {
      state.fabricatorThdViewerOptions[key] = option
    },
    setFabricatorInputClearTrigger: (state) => {
      state.fabricatorInputClearTrigger = !state.fabricatorInputClearTrigger
    },
    setFabricatorEstimatedProcessInfos: (state, { step, value }) => {
      if (!value) {
        state.fabricatorEstimatedProcessInfos[step] = { status: 'loading' }
        return
      }

      state.fabricatorEstimatedProcessInfos[step] = { status: 'done', value }
    },
    claerFabricatorEstimatedProcessInfos: (state) => {
      state.fabricatorEstimatedProcessInfos = {}
    },
    setFabricatorVersionHistorySelectedImage: (state, value) => {
      state.fabricatorVersionHistorySelectedImage = value
    },
    setFabricatorFocusVersionHistoryTrigger: (state, key) => {
      state.fabricatorFocusVersionHistoryTrigger[key] = !state.fabricatorFocusVersionHistoryTrigger[key]
    },
    setFabricatorNumberOfOutputsHover: (state, number) => {
      state.fabricatorNumberOfOutputsHover = number
    },
    setFabricatorCurrentProgressQueueInfo: (state, info) => {
      state.fabricatorCurrentProgressQueueInfo = info
    },
    setFabricatorTipContentsType: (state, type) => {
      state.fabricatorTipContentsType = type
    },
    setFabricatorImageCroppedOptions: (state, options) => {
      state.fabricatorImageCroppedOptions = options
    },
  },
}

export default fabricator
