/* eslint-disable no-param-reassign */
import axios from '@/modules/axios'
import customerService from '@/services/customer'
import helpers from '@/helpers'
import { router } from '@/router'

const customer = {
  state: () => ({
    me: null,
    header: null,
    signInAndSignUpInfo: null,
    subscriptionPlans: null,
    authSubscriptionPlans: null,
    referralCampaigns: null,
    isShowAdminButton: true,
  }),
  getters: {
    me: ({ me }) => me,
    header: ({ header }) => header,
    signInAndSignUpInfo: ({ signInAndSignUpInfo }) => signInAndSignUpInfo,
    subscriptionPlans: ({ subscriptionPlans }) => subscriptionPlans,
    authSubscriptionPlans: ({ authSubscriptionPlans }) => authSubscriptionPlans,
    referralCampaigns: ({ referralCampaigns }) => referralCampaigns,
    isShowAdminButton: ({ isShowAdminButton }) => isShowAdminButton,
  },
  actions: {
    loadAuthToken({ commit, dispatch }) {
      const header = window.localStorage.getItem('header')

      if (!header) return Promise.reject()

      commit('addHeader', JSON.parse(header))
      dispatch('setRequestHeader')
    },
    setRequestHeader({ getters }) {
      axios.setRequestHeader(getters.header)
    },
    async signIn({ dispatch, commit, getters }, params) {
      try {
        if (getters.signInAndSignUpInfo?.isCLOSignInProcess) {
          const { token, refreshToken, workspaceId, memberId } = await customerService.signIn.clo(params)
          commit('addSignInAndSignUpInfo', { cloWorkspaceId: workspaceId })

          await dispatch('setToken', { token, refreshToken })
        } else {
          const data = await customerService.signIn.submit(params)

          await dispatch('setToken', data)
        }

        helpers.gtm({
          event: 'userLoggedIn',
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async signUp({ dispatch, commit, getters }, params) {
      try {
        if (getters.signInAndSignUpInfo?.isCLOSignInProcess) {
          const { token, refreshToken, workspaceId, memberId } = await customerService.signUp.clo(params)
          commit('addSignInAndSignUpInfo', { cloWorkspaceId: workspaceId })

          await dispatch('setToken', { token, refreshToken })
        } else {
          const data = await customerService.signUp.submit(params)

          await dispatch('setToken', data)
        }

        helpers.gtm({
          event: 'createAccount',
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async socialSignIn({ dispatch }, params) {
      try {
        const data = await customerService.signIn.social(params)
        await dispatch('setToken', data)

        helpers.gtm({
          event: 'userLoggedIn',
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async socialSignUp({ dispatch }, params) {
      try {
        const data = await customerService.signUp.social(params)
        await dispatch('setToken', data)

        helpers.gtm({
          event: 'createAccount',
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async setToken({ commit, dispatch }, { refreshToken, token, currentWorkspaceId }) {
      const header = { token, refreshToken }

      if (currentWorkspaceId) {
        header['current-workspace-id'] = currentWorkspaceId
      }

      commit('addHeader', header)
      window.localStorage.setItem('header', JSON.stringify(header))
      dispatch('setRequestHeader')
    },
    async loadMe({ commit }) {
      try {
        const me = await customerService.me.all()

        commit('setMe', me)

        helpers.gtm({
          event: 'setUser',
          'user.id': me.id,
          'user.email': me.email,
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async logout({ commit, dispatch }, callback) {
      try {
        await customerService.me.signout()

        if (router.currentRoute.value.name === 'ViewSurvey') {
          commit('setMe', null)
        }

        router.push('/').then(() => {
          dispatch('removeHeader')
          window.localStorage.removeItem('header')
          helpers.localStorage.deleteMeta('isSurveySubmitted')
          commit('initAppData')
          dispatch('bootstrap')
          commit('setHeaderTheme', 'dark')

          if (callback) callback()
        })
      } catch (e) {
        return Promise.reject(e)
      }
    },
    removeHeader({ commit }) {
      commit('setHeader', null)
      axios.clearRequestHeader()
    },
    async loadSubscriptionPlans({ commit }) {
      try {
        const { data } = await customerService.subscriptionPlan.all()

        commit('setSubscriptionPlans', data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async loadAuthSubscriptionPlans({ commit }) {
      try {
        const { data } = await customerService.subscriptionPlan.authAll()

        commit('setAuthSubscriptionPlans', data)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async loadReferralCampaigns({ commit }) {
      try {
        const campaigns = await customerService.getReferralCampaigns()
        commit('setReferralCampaigns', campaigns)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    setMarketingReferrerHeader({ commit, dispatch }, referralCampaign) {
      commit('addHeader', { 'marketing-referrer': JSON.stringify(referralCampaign) })
      dispatch('setRequestHeader')
    },
    loadLocalIsShowAdminButton({ commit, getters }) {
      if (!getters.isSSR) {
        commit('setIsShowAdminButton', JSON.parse(helpers.localStorage.getMeta('isShowAdminButton') || 'true'))
      }
    },
  },
  mutations: {
    setMe(state, me) {
      state.me = me
    },
    setHeader(state, header) {
      state.header = header
    },
    addHeader(state, newHeader) {
      Object.entries(newHeader).forEach(([key, value]) => {
        if (!state.header) {
          state.header = {}
        }

        state.header[key] = value
      })
    },
    addSignInAndSignUpInfo(state, newInfos) {
      Object.entries(newInfos).forEach(([key, value]) => {
        if (!state.signInAndSignUpInfo) {
          state.signInAndSignUpInfo = {}
        }

        state.signInAndSignUpInfo[key] = value
      })
    },
    setSignInAndSignUpInfo(state, signInAndSignUpInfo) {
      state.signInAndSignUpInfo = signInAndSignUpInfo
    },
    setSubscriptionPlans(state, subscriptionPlans) {
      state.subscriptionPlans = subscriptionPlans
    },
    setAuthSubscriptionPlans(state, authSubscriptionPlans) {
      state.authSubscriptionPlans = authSubscriptionPlans
    },
    setReferralCampaigns(state, referralCampaigns) {
      state.referralCampaigns = referralCampaigns
    },
    setIsShowAdminButton(state, isShowAdminButton) {
      state.isShowAdminButton = isShowAdminButton
    },
  },
}

export default customer
