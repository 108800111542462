<template>
  <div ref="refAppMultiSelect" class="app-multi-select" :class="className">
    <div class="contents-wrapper" @click="onClickContentsWrapper">
      <span v-if="selectedOptions.length === 0" class="placeholder">{{ placeholder }}</span>

      <div v-for="selectedOption in selectedOptions" :key="selectedOption" class="selected-option">
        <span>{{ selectedOption }}</span>
        <i class="far fa-xmark" @click.prevent="onClickOption(selectedOption)" />
      </div>
    </div>

    <i class="toggle-icon fa fa-caret-down" />

    <button class="select-click-area" @click="onClickAppMultiSelect()" />
  </div>

  <WrapperDropdownOverlay
    v-if="isOpenedDropdown"
    ref="refAppMultiSelectDropdown"
    class="app-multi-select-dropdown"
    :mountBelow="refAppMultiSelect"
    :isUseResizeClose="isUseResizeClose"
    @close="isOpenedDropdown = false"
  >
    <ul :style="{ width: `${refAppMultiSelect.offsetWidth}px` }">
      <template v-if="!isUseCustomDropdown">
        <li v-for="option in options" :key="option" @click="onClickOption(option)">{{ option }}</li>
      </template>
      <slot v-else />
    </ul>
  </WrapperDropdownOverlay>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['set-selected-options'])

const props = defineProps({
  className: {
    type: Object,
    default: () => ({}),
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  selectedOptions: {
    type: Array,
    default: () => [],
  },
  isUseCustomDropdown: Boolean,
  isUseResizeClose: {
    type: Boolean,
    default: true,
  },
})

const refAppMultiSelect = ref()
const refAppMultiSelectDropdown = ref()

const isOpenedDropdown = ref(false)

const onClickAppMultiSelect = () => {
  isOpenedDropdown.value = true
}

const onClickOption = (option) => {
  const { selectedOptions } = props

  emit(
    'set-selected-options',
    selectedOptions.includes(option) ?
      selectedOptions.filter((value) => value !== option) :
      [...selectedOptions, option],
  )
}

const onClickContentsWrapper = ({ target }) => {
  if (target.className === 'contents-wrapper') {
    onClickAppMultiSelect()
  }
}
</script>

<style lang="scss">
.app-multi-select {
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--gs-e0);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 15px;
  gap: 8px;

  .contents-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    z-index: 1;
    cursor: pointer;

    .placeholder {
      font-weight: 400;
      font-size: 16px;
      color: var(--gs-af);
    }

    .selected-option {
      display: flex;
      align-items: center;
      column-gap: 20px;
      padding: 7.5px 12px;
      background-color: var(--gs-f8);
      border: 1px solid var(--gs-e0);
      border-radius: 4px;
      font-size: 14px;
      cursor: default;

      span {
        font-weight: 400;
        color: var(--gs-4d);
      }

      i {
        color: var(--gs-7a);
        cursor: pointer;
      }
    }
  }

  .toggle-icon {
    font-size: 16px;
    color: var(--gs-7a);
  }

  .select-click-area {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.app-multi-select-dropdown ul {
  transform: translateY(-1px);
  border: 1px solid var(--gs-e0);
  border-radius: 0px 0px 4px 4px;
  background-color: var(--white);
  filter: drop-shadow(0px 4px 8px var(--black-20));

  li {
    padding: 8px 16px;
    font-weight: 400;
    font-size: 14px;
    color: var(--gs-4d);
    cursor: pointer;
  }
}
</style>
