/* eslint-disable no-param-reassign */
import { $http } from '@/modules/axios'
import helpers from '@/helpers/index'
import createBus from '@/helpers/bus'
import models from '@/models'

export default {
  install: (app) => {
    app.config.globalProperties.$helpers = helpers

    const { translate, tooltip, modal, toast } = helpers

    app.config.globalProperties.$t = translate
    app.config.globalProperties.$tooltip = tooltip
    app.config.globalProperties.$modal = modal
    app.config.globalProperties.$toast = toast

    app.config.globalProperties.$http = $http
    app.config.globalProperties.$bus = createBus()
    app.config.globalProperties.$models = models
  },
}
