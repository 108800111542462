<template>
  <div class="app-pagination" :class="theme">
    <i class="chevron clickable far fa-chevron-left" @click="onPage(currentPageNumber - 1)" />

    <div
      v-for="pageNumber in refineShowingPageNumbers(currentPageNumber, lastPageNumber, wingLength)"
      :key="pageNumber"
      class="page-number"
      :class="{
        selected: pageNumber === currentPageNumber,
        clickable: !['...', currentPageNumber].includes(pageNumber),
      }"
      @click="onPage(pageNumber)"
      v-html="pageNumber"
    />

    <i class="chevron clickable far fa-chevron-right" @click="onPage(currentPageNumber + 1)" />
  </div>
</template>

<script setup>
const emit = defineEmits(['on-page'])

const props = defineProps({
  currentPageNumber: {
    type: Number,
    default: 1,
    required: true,
  },
  lastPageNumber: {
    type: Number,
    required: true,
  },
  wingLength: {
    type: Number,
    default: 2,
  },
  theme: {
    type: String,
    default: 'light',
  },
})

const onPage = (value) => {
  if (value === '...' || value < 1 || value > props.lastPageNumber) {
    return
  }

  emit('on-page', parseInt(value, 10))
}

const refineShowingPageNumbers = (currentPageNumber, lastPageNumber, wingLength) => {
  const startPageNumber = currentPageNumber - wingLength

  const refinedPageNumbers = []

  if (startPageNumber > 1) {
    refinedPageNumbers.push(1)
  }

  if (startPageNumber > 2) {
    refinedPageNumbers.push('...')
  }

  for (let i = startPageNumber; i <= currentPageNumber + wingLength; i += 1) {
    if (i > 0 && i <= lastPageNumber) {
      refinedPageNumbers.push(i)
    }
  }

  if (refinedPageNumbers.findIndex((i) => i === lastPageNumber) === -1) {
    if (refinedPageNumbers.findIndex((i) => i === lastPageNumber - 1) === -1) {
      refinedPageNumbers.push('...')
    }

    refinedPageNumbers.push(lastPageNumber)
  }

  return refinedPageNumbers
}
</script>

<style lang="scss" scoped>
.app-pagination {
  display: flex;
  align-items: center;

  > * {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    font-size: 16px;
    cursor: default;
    transition: all 0.4s;

    &.clickable {
      cursor: pointer;
      transition: all 0.4s;
    }
  }

  .page-number {
    font-weight: 500;
    border-bottom: 2px solid transparent;
  }

  &.light {
    > * {
      color: var(--black-30);

      &.clickable {
        &:hover {
          background: var(--black-10);
        }

        &:active {
          color: var(--black);
        }
      }
    }

    .page-number.selected {
      color: var(--black);
      border-color: var(--black);
    }
  }

  &.dark {
    > * {
      color: var(--white-50);

      &.clickable {
        &:hover {
          background: var(--white-10);
        }

        &:active {
          color: var(--white);
        }
      }
    }

    .page-number.selected {
      color: var(--white);
      border-color: var(--white);
    }
  }

  @media (max-width: 991px) {
    > * {
      font-size: 14px;
    }

    .page-number {
      font-size: 12px;
    }
  }
}
</style>
