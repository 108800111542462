<template>
  <i
    ref="refAppTooltipIcon"
    class="app-tooltip-icon"
    :class="iconClass"
    @click="onClickTooltipIcon()"
    @mouseover="onMouseoverTooltipIcon()"
    @mouseleave="onMouseleaveTooltipIcon()"
  />
</template>

<script setup>
import { ref, onUnmounted, watchEffect } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  iconClass: {
    type: String,
    default: 'far fa-circle-question',
  },
  tooltipOptions: {
    type: Object,
    default: () => ({}),
  },
  scrollObservedTarget: {
    type: Object,
    default: () => ({}),
  },
  isAvailableTooltipMousemove: Boolean,
})

const { plugins, store } = useGlobalHooks()

const refAppTooltipIcon = ref()

const onClickTooltipIcon = () => {
  if (store.getters.isTablet) {
    showTooltip()
  }
}

const onMouseoverTooltipIcon = () => {
  if (!store.getters.isTablet) {
    showTooltip()
  }
}

const onMouseleaveTooltipIcon = () => {
  if (props.isAvailableTooltipMousemove) return

  if (!store.getters.isTablet) {
    hideTooltip()
  }
}

const showTooltip = () => {
  store.commit('removeAllTooltips')

  const { tooltipOptions, isAvailableTooltipMousemove } = props

  plugins.$helpers.tooltip.show({
    ...tooltipOptions,
    showAbove: refAppTooltipIcon,
    scrollClose: true,
    isAvailableTooltipMousemove,
  })
}

const hideTooltip = () => plugins.$helpers.tooltip.hide(props.tooltipOptions.id)

const onParentScroll = () => {
  if (store.getters.isTablet) {
    hideTooltip()
  }
}

watchEffect(() => {
  if (!props.scrollObservedTarget.nodeName) return

  props.scrollObservedTarget.addEventListener('scroll', onParentScroll, { capture: true })
})

onUnmounted(() => {
  if (!props.scrollObservedTarget.nodeName) return

  props.scrollObservedTarget.removeEventListener('scroll', onParentScroll)
})
</script>

<style lang="scss" scoped>
.app-tooltip-icon {
  font-size: 14px;
  color: var(--gs-4d);
  cursor: pointer;
}
</style>
