<template>
  <teleport :to="to" :disabled="refinedDisabled">
    <slot />
  </teleport>
</template>

<script setup>
import { computed } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  to: {
    type: String,
    default: '#app',
  },
  useTeleport: {
    type: Boolean,
    default: true,
  },
})

const { store } = useGlobalHooks()

const refinedDisabled = computed(() => {
  if (!store.getters.useTeleport) return true

  return !props.useTeleport
})
</script>
