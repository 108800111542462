import { callWrappedApi } from '@/modules/axios'

const fabricatorService = {
  betaEnlist: {
    get: () => callWrappedApi({ method: 'get', url: 'library/fabricator_enlists/enlisted' }),
    enlist: (data) => callWrappedApi({ method: 'post', url: 'library/fabricator_enlists/', data }),
  },
  textureGeneration: {
    list: (params) => callWrappedApi({ method: 'get', url: 'library/texture_generations', params }),
    detail: (sharingKey) => callWrappedApi({ method: 'get', url: `library/texture_generations/${sharingKey}` }),
    process: (data) => callWrappedApi({ method: 'post', url: 'library/texture_generations/process', data }),
    tokenization: (prompt, negative = false) =>
      callWrappedApi({
        method: 'post',
        url: 'library/texture_generations/prompt/tokenization',
        data: { prompt, negative },
      }),
    restore: (sharingKey, versionHistoryId) =>
      callWrappedApi({
        method: 'put',
        url: `library/texture_generations/${sharingKey}/restore`,
        data: { versionHistoryId },
      }),
  },
  versionHistory: {
    list: (params) => callWrappedApi({ method: 'get', url: 'library/version_histories', params }),
    detail: (id) => callWrappedApi({ method: 'get', url: `library/version_histories/${id}` }),
    status: (id, type) =>
      callWrappedApi({ method: 'get', url: `library/version_histories/${id}/status`, params: { type } }),
    cancel: (id) => callWrappedApi({ method: 'post', url: `library/version_histories/${id}/cancel` }),
    seedImage: (id) => callWrappedApi({ method: 'get', url: `library/version_histories/${id}/seed_image` }),
    feedbackOptions: (params) =>
      callWrappedApi({ method: 'get', url: 'library/version_histories/feedback_options', params }),
    feedback: (id, data) => callWrappedApi({ method: 'post', url: `/library/version_histories/${id}/feedback`, data }),
    requestFile: (id) => callWrappedApi({ method: 'post', url: `library/version_histories/${id}/file` }),
    fileUrl: ({ id, type }) =>
      callWrappedApi({ method: 'get', url: `library/version_histories/${id}/file_url`, params: { type } }),
    processInfos: (step) =>
      callWrappedApi({ method: 'get', url: '/library/version_histories/generation_infos', params: { step } }),
    copySwatch: (id, versionHistoryImageId) =>
      callWrappedApi({
        method: 'post',
        url: `library/version_histories/${id}/copied_swatch`,
        data: { versionHistoryImageId },
      }),
  },
  filters: () => callWrappedApi({ method: 'get', url: 'library/fabricator_filters' }),
  prompt: {
    list: (params) => callWrappedApi({ method: 'get', url: '/library/prompt_generations', params }),
    generate: (data) => callWrappedApi({ method: 'post', url: '/library/prompt_generations', data }),
  },
}

export default fabricatorService
