<template>
  <div class="app-badge" :class="options.type">
    <span v-html="refinedHTML" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
})

const { store } = useGlobalHooks()

const refinedHTML = computed(() => {
  const { html, forcedHTML } = props.options

  if (forcedHTML) return forcedHTML

  if (html !== 'CLO Enterprise') return html

  if (store.getters.isMobile) return 'CLO'

  return html
})
</script>

<style lang="scss" scoped>
.app-badge {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;

  &.clo-enterprise-badge {
    background: conic-gradient(
      from 167deg at 62.89% 52.38%,
      #754af2 0deg,
      #5652f4 146.58895611763deg,
      #6c9bfd 240.34451007843018deg,
      #87daf6 360deg
    );
    color: var(--white);
  }
}
</style>
