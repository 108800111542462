import helpers from '@/helpers'
import { store as $store } from '@/store'

export default async (to, from, next) => {
  const isSSR = process.env.VUE_APP_SSR

  if ((to || {}).fullPath && !isSSR) {
    helpers.localStorage.setMeta('prevFullPath', to.fullPath)
  }

  const { me } = $store.getters
  if (me) {
    next()
    return
  }

  let prevFullPath

  if (!isSSR) {
    prevFullPath = helpers.localStorage.getMeta('prevFullPath')
  }

  try {
    await $store.dispatch('loadAuthToken')
    next()
  } catch (e) {
    next(`/sign-in/select/${prevFullPath ? `?moveAfterSignIn=${prevFullPath}` : ''}`)
  }
}
