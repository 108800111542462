<template>
  <button
    class="app-button-v2"
    :class="[buttonType]"
    :disabled="disabled"
    :style="{
      backgroundColor: refineBackgroundColor(buttonType, mainColor),
      borderColor: mainColor,
      color: fontColor,
    }"
  >
    <div class="button-overlay" :class="[`hover-theme-${hoverTheme}`]" />
    <span v-html="buttonText" />
  </button>
</template>

<script setup>
defineProps({
  buttonType: {
    type: String,
    default: 'solid',
    validator: (val) => ['solid', 'line'].includes(val),
  },
  mainColor: {
    type: String,
    default: 'var(--gs-21)',
  },
  fontColor: {
    type: String,
    default: 'var(--white)',
  },
  buttonText: {
    type: String,
    required: true,
  },
  hoverTheme: {
    type: String,
    default: 'default',
    validator: (val) => ['default', 'black'].includes(val),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
})

const refineBackgroundColor = (buttonType, mainColor) => {
  if (buttonType === 'line') {
    return 'transparent'
  }

  return mainColor
}
</script>

<style lang="scss" scoped>
.app-button-v2 {
  z-index: 1;
  position: relative;
  padding: 12.5px 0;
  max-height: 48px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid;
  transition: background-color 0.4s, border-color 0.4s, color 0.4s;

  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    column-gap: 12px;
    font-weight: 500;
    font-size: 15px;
    text-transform: uppercase;
  }

  .button-overlay {
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    background-color: transparent;
    user-select: none;
    pointer-events: none;
    transition: background-color 0.4s;
  }

  &:hover .button-overlay {
    .hover-theme-default {
      background-color: var(--white-20);
    }

    .hover-theme-black {
      background-color: var(--black-5);
    }
  }

  &:active .button-overlay {
    background-color: var(--black-10) !important;
  }

  &:disabled {
    background-color: var(--gs-f8) !important;
    border-color: var(--gs-e0) !important;
    color: var(--gs-c5) !important;
    pointer-events: none;
  }

  @media (max-width: 991px) {
    padding: 9.5px 0;
    max-height: 40px;

    span {
      font-size: 14px;
    }
  }
}
</style>
