import { callWrappedApi } from '@/modules/axios'

const itemListService = {
  twinFabrics: {
    all: (params) => callWrappedApi({ method: 'get', url: 'library/presets', params }),
    detail: (id, purchased) => callWrappedApi({ method: 'get', url: `/library/presets/${id}`, params: { purchased } }),
    count: (params) => callWrappedApi({ method: 'get', url: 'library/presets/count', params }),
  },
  textures: {
    all: (params) => callWrappedApi({ method: 'get', url: 'library/texture_presets', params }),
    detail: (id, purchased) =>
      callWrappedApi({ method: 'get', url: `/library/texture_presets/${id}`, params: { purchased } }),
    count: (params) => callWrappedApi({ method: 'get', url: 'library/texture_presets/count', params }),
  },
  drapeTemplates: {
    all: (params) => callWrappedApi({ method: 'get', url: 'library/drape_templates', params }),
    detail: (id, purchased) =>
      callWrappedApi({ method: 'get', url: `/library/drape_templates/${id}`, params: { purchased } }),
    count: (params) => callWrappedApi({ method: 'get', url: 'library/drape_templates/count', params }),
  },
  pinnedItems: {
    all: (params) => callWrappedApi({ method: 'get', url: 'library/pinned_items', params }),
    isPinned: (params) => callWrappedApi({ method: 'get', url: 'library/pinned_items/pinned', params }),
    pin: (params) => callWrappedApi({ method: 'post', url: 'library/pinned_items/pin', data: params }),
    unpin: (params) => callWrappedApi({ method: 'post', url: 'library/pinned_items/unpin', data: params }),
  },
  purchasedItems: {
    all: (params) => callWrappedApi({ method: 'get', url: 'library/purchased_items', params }),
    isPurchased: (params) => callWrappedApi({ method: 'get', url: 'library/purchased_items/purchased', params }),
    purchase: (params) => callWrappedApi({ method: 'post', url: 'library/purchased_items/purchase', data: params }),
    download: (params) => callWrappedApi({ method: 'get', url: 'library/files', params }),
  },
  count: () => callWrappedApi({ method: 'get', url: 'library/items/count' }),
  filters: (itemType) => callWrappedApi({ method: 'get', url: 'library/assets/filters', params: { itemType } }),
}

export default itemListService
