import axios from 'axios'
import { trace } from '@opentelemetry/api'
import { store } from '@/store'
import helpers from '@/helpers'
import { handleUnauthorizedError, handleCurrentWorkspaceIdError, handleManageableError } from './axios-error-handling'

// eslint-disable-next-line import/no-mutable-exports
export let $http = {}

export const setHttpClient = (o) => {
  $http = o
}

export const createHttpClient = () => {
  const httpClient = axios.create()

  httpClient.interceptors.request.use((req) => {
    req.headers['is-ssr'] = !!process.env.VUE_APP_SSR
    if (req.headers['is-ssr'] && store.state.axiosHeaderSSR) {
      Object.keys(store.state.axiosHeaderSSR).forEach((key) => {
        req.headers[key] = store.state.axiosHeaderSSR[key]
      })
    }

    return req
  })

  httpClient.interceptors.response.use(
    (res) => res.data,
    async (err) => {
      if (!err.response) {
        throw err
      }

      const handlingResults = await Promise.all([
        handleUnauthorizedError(err),
        handleCurrentWorkspaceIdError(err),
        handleManageableError(err),
      ])

      if (handlingResults.findIndex((result) => result) === -1) {
        helpers.reportError(err)
      }

      throw err.response
    },
  )
  httpClient.defaults.baseURL = process.env.VUE_APP_API_DOMAIN
  return httpClient
}

const headerKeys = ['Authorization', 'marketing-referrer', 'current-workspace-id']

export const setRequestHeader = (header) => {
  if (header.token) $http.defaults.headers.Authorization = `Bearer ${header.token}`

  Object.keys(header).forEach((key) => {
    if (!header[key] || !headerKeys.includes(key)) return
    $http.defaults.headers[key] = header[key]
  })
}

export const clearRequestHeader = () => {
  headerKeys.forEach((headerKey) => {
    if ($http.defaults.headers[headerKey]) delete $http.defaults.headers[headerKey]
  })
}

const checkWorkspaceId = () => {
  const { isSSR, header } = store.getters

  if (isSSR) return true

  const headerWorkspaceId = (header || {})['current-workspace-id']
  const localStorageWorkspaceId = JSON.parse(window.localStorage.getItem('header') || '""')['current-workspace-id']

  if (localStorageWorkspaceId && headerWorkspaceId && localStorageWorkspaceId !== headerWorkspaceId) {
    return false
  }

  return true
}

const getTraceparent = (url, method, params, data) => {
  const tracer = trace.getTracer('vmode-fabric-frontend')
  const span = tracer.startSpan(url.replaceAll('/', '-'))

  span.setAttribute('method', method)
  span.setAttribute('url', url)

  const { isSSR, me } = store.getters

  if (!isSSR) {
    span.setAttribute('user-agent', navigator.userAgent)
  }

  if (me) {
    span.setAttribute('user-id', me.id)
    span.setAttribute('current-workspace-id', me.currentWorkspace.id)
  }

  if (params) {
    span.setAttribute('params', JSON.stringify(params))
  }

  if (data) {
    span.setAttribute('body', JSON.stringify(data))
  }

  const c = span.spanContext()
  const traceparent = `00-${c.traceId}-${c.spanId}-0${c.traceFlags.toString(16)}`

  return { span, traceparent }
}

export const callWrappedApi = async ({ method, url, params, data, config }) => {
  if (!checkWorkspaceId()) return Promise.reject(new Error('Workspace ID is not set'))

  const { span, traceparent } = getTraceparent(url, method, params, data)

  try {
    const res = await $http({
      method,
      url,
      headers: {
        traceparent,
      },
      params,
      data,
      ...config,
    })

    return res
  } catch (error) {
    return Promise.reject(error)
  } finally {
    span.end()
  }
}

export default {
  $http,
  createHttpClient,
  setHttpClient,
  setRequestHeader,
  clearRequestHeader,
  callWrappedApi,
}
