import { router } from '@/router'
import { store } from '@/store'
import helpers from '@/helpers'
import { DEFAULT_ERROR_TOAST_TITLE, ERROR_CODES } from '@/assets/constants'

const resetTokenAndRedirect = (redirectRoute = '/') => {
  store.dispatch('removeHeader')
  window.localStorage.removeItem('header')
  store.commit('initAppData')
  store.commit('setHeaderTheme', 'dark')
  store.dispatch('bootstrap', 'no-auth')
  router.push(redirectRoute).then(showToastError)
}

const showToastError = () => {
  helpers.toast.error({
    html: DEFAULT_ERROR_TOAST_TITLE,
  })
}

export const handleUnauthorizedError = async (error) => {
  const { status, data } = error.response || {}
  const { httpStatus, code } = data || {}

  if ([ERROR_CODES.FABRICATOR_UNQUALIFIED].includes(code)) return false

  if ([ERROR_CODES.UNAUTHORIZED, ERROR_CODES.TOKEN_EXPIRED, ERROR_CODES.TOKEN_NOT_FOUND].includes(code)) {
    resetTokenAndRedirect()
    return true
  }

  if (code === ERROR_CODES.CUSTOMER_DEACTIVATED) {
    // ERROR: 계정이 삭제된 Case
    await store.dispatch('logout', showToastError)
    return true
  }

  if (httpStatus === 401 || status === 401) {
    // ERROR: 기타 Unauthorized Case
    resetTokenAndRedirect()
  }

  return false
}

export const handleCurrentWorkspaceIdError = async (error) => {
  const { code } = error.response?.data || {}
  const { MEMBER_ACCESS_LIMITED, WORKSPACE_INACTIVE, WORKSPACE_INACCESSBILE } = ERROR_CODES

  if (![MEMBER_ACCESS_LIMITED, WORKSPACE_INACTIVE, WORKSPACE_INACCESSBILE].includes(code)) {
    return false
  }

  if (code === MEMBER_ACCESS_LIMITED) {
    // Limited Access 유저 오류
    helpers.modal
      .basicV2({
        titleIcon: 'error-v2',
        body: `Your current access level does not permit this action.<br/>
      Please contact your administrator.`,
      })
      .then(() => {
        router.push('/my-account')
      })

    return true
  }

  const { otherWorkspaces } = store.getters.me || {}

  const targetPersonalWorkspaceId = (
    (otherWorkspaces || []).find(({ workspaceType }) => workspaceType === 'personal') || {}
  ).id

  if (!targetPersonalWorkspaceId) {
    resetTokenAndRedirect()
    return false
  }

  store.dispatch('setCurrentWorkspaceIdHeader', targetPersonalWorkspaceId)

  await store.dispatch('loadMe')

  router.push('/my-account').then(() => {
    helpers.toast.error({
      html: '<b>You do not have access to this workspace</b>',
    })
  })
  return true
}

export const handleManageableError = async (error) => {
  const { code } = error.response?.data || {}

  if (
    [
      ERROR_CODES.UNAUTHORIZED,
      ERROR_CODES.ACCESS_DENIED,
      ERROR_CODES.RECORD_NOT_FOUND,
      ERROR_CODES.TOKEN_NOT_FOUND,
      ERROR_CODES.TOKEN_EXPIRED,
      ERROR_CODES.VERSION_HISTORY_ANOTHER_ONGOING,
      ERROR_CODES.CUSTOMER_DEACTIVATED,
      ERROR_CODES.CUSTOMER_SOCIAL_SIGNIN_ERROR,
      ERROR_CODES.CUSTOMER_INVITATION_EMAIL_MISMATCH,
      ERROR_CODES.CUSTOMER_NO_CREDIT_LEFT,
      ERROR_CODES.CUSTOMER_NOT_FOUND,
      ERROR_CODES.CUSTOMER_INVALID_PASSWORD,
      ERROR_CODES.ORDER_DOCUMENT_PROCESSING,
      ERROR_CODES.ORDER_PROCESSING,
      ERROR_CODES.MEMBER_ACCESS_LIMITED,
      ERROR_CODES.WORKSPACE_INACTIVE,
      ERROR_CODES.WORKSPACE_MAXIMUM,
      ERROR_CODES.WORKSPACE_INACCESSBILE,
      ERROR_CODES.WORKSPACE_SWITCH_NEEDED,
      ERROR_CODES.PROMO_CODE_INVALID,
      ERROR_CODES.PROMO_CODE_ALREADY_USED,
    ].includes(code)
  ) {
    return true
  }

  return false
}
