import createApp from './main'
import sentryVue from '@/helpers/sentry-vue'

const boot = async ({ app, router, store }) => {
  const storeInitialState = window.INITIAL_DATA

  await router.isReady()
  if (storeInitialState) {
    store.replaceState(storeInitialState)
  }

  sentryVue.useSentry(app)
  app.mount('#app')
}

boot(createApp())
