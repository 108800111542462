import { callWrappedApi } from '@/modules/axios'

const customerService = {
  me: {
    all: () => callWrappedApi({ method: 'get', url: 'library/customers/me' }),
    update: (data) => callWrappedApi({ method: 'put', url: 'library/customers/me', data }),
    signout: () => callWrappedApi({ method: 'post', url: 'library/customers/signout' }),
    survey: (data) => callWrappedApi({ method: 'put', url: 'library/customers/submit_survey', data }),
  },
  signIn: {
    enter: (data) => callWrappedApi({ method: 'post', url: 'library/customers/enter', data }),
    submit: (data) => callWrappedApi({ method: 'post', url: 'library/customers/sign_in', data }),
    social: (data) => callWrappedApi({ method: 'post', url: 'library/customers/social_sign_in', data }),
    clo: (data) => callWrappedApi({ method: 'post', url: 'library/customers/clo_sign_in', data }),
  },
  resetPassword: {
    sendEmail: (data) => callWrappedApi({ method: 'post', url: 'library/customers/passwords/request', data }),
    verify: (data) => callWrappedApi({ method: 'post', url: 'library/customers/passwords/verify', data }),
    reset: (data) => callWrappedApi({ method: 'put', url: 'library/customers/passwords/reset', data }),
  },
  signUp: {
    submit: (data) => callWrappedApi({ method: 'post', url: 'library/customers/sign_up', data }),
    social: (data) => callWrappedApi({ method: 'post', url: 'library/customers/social_sign_up', data }),
    clo: (data) => callWrappedApi({ method: 'post', url: 'library/customers/clo_sign_up', data }),
  },
  subscriptionPlan: {
    all: () => callWrappedApi({ method: 'get', url: 'library/subscription_plans' }),
    authAll: () => callWrappedApi({ method: 'get', url: 'library/subscription_plans/by_customer' }),
  },
  emailVerification: {
    request: () => callWrappedApi({ method: 'post', url: 'library/customers/emails/request' }),
    verify: (token) => callWrappedApi({ method: 'post', url: 'library/customers/emails/verify', data: { token } }),
  },
  getReferralCampaigns: () => callWrappedApi({ method: 'get', url: 'library/referral_campaigns' }),
}

export default customerService
