import { callWrappedApi } from '@/modules/axios'

const collectionService = {
  all: (params) => callWrappedApi({ method: 'get', url: 'library/collections', params }),
  detail: (id, params) => callWrappedApi({ method: 'get', url: `/library/collections/${id}`, params }),
  create: (data) => callWrappedApi({ method: 'post', url: '/library/collections', data }),
  update: (id, data) => callWrappedApi({ method: 'put', url: `/library/collections/${id}`, data }),
  delete: (id) => callWrappedApi({ method: 'delete', url: `/library/collections/${id}` }),
  pin: (data) => callWrappedApi({ method: 'post', url: '/library/pinned_items/pin', data }),
}

export default collectionService
