import { createStore } from 'vuex'
import app from './app'
import config from './config'
import customer from './customer'
import ssr from './ssr'
import workspace from './workspace'
import digitizationOrder from './digitizationOrder'
import fabricatorAdmin from './fabricator-admin'
import itemList from './item-list'
import collection from './collection'
import fabricator from './fabricator'

const initialState = {
  app: app.state,
  config: config.state,
  customer: customer.state,
  ssr: ssr.state,
  workspace: workspace.state,
  digitizationOrder: digitizationOrder.state,
  fabricatorAdmin: fabricatorAdmin.state,
  itemList: itemList.state,
  collection: collection.state,
  fabricator: fabricator.state,
}

export const newStore = () =>
  createStore({
    modules: {
      app,
      config,
      customer,
      ssr,
      workspace,
      digitizationOrder,
      fabricatorAdmin,
      itemList,
      collection,
      fabricator,
    },
    mutations: {
      initAppData(state) {
        Object.keys(state).forEach((key) => {
          const foo = initialState[key]
          if (!foo) {
            console.error(
              `'${key}'의 initialState가 정의되어 있지 않습니다. 이는 로그아웃시 앱이 올바로 초기화되지 않는 문제를 야기할 수 있습니다.`,
            )
            return
          }

          Object.assign(state[key], foo())
        })
      },
    },
  })

// eslint-disable-next-line import/no-mutable-exports
export let store = {}

export const setStore = (o) => {
  store = o
}

export default {
  store,
  setStore,
  newStore,
}
