<template>
  <div class="page-sidebar-wrapper">
    <div v-if="!$store.getters.isTablet" class="sidebar-wrapper">
      <slot name="sidebar-component" />
    </div>

    <div class="contents-wrapper">
      <div ref="refPageContents" class="page-contents flex" :class="pageContentsClassName">
        <slot name="contents" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { watch, ref, onMounted } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

defineProps({
  pageContentsClassName: {
    type: String,
    default: '',
  },
})

const { store } = useGlobalHooks()

const refPageContents = ref()
const setRect = () => {
  if (!refPageContents.value) return

  store.commit('setSidebarContentsRect', refPageContents.value.getBoundingClientRect())
}

onMounted(setRect)
watch(() => [store.getters.scrollTop, store.getters.windowInnerWidth, store.getters.windowInnerHeight], setRect)
</script>

<style lang="scss" scoped>
.page-sidebar-wrapper {
  display: flex;
  --sidebar-width: 288px;

  > .sidebar-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: calc(50% - 960px + var(--sidebar-width));
  }

  > .contents-wrapper {
    display: flex;
    width: calc(50% + 960px - var(--sidebar-width));

    > div {
      width: 100%;
      max-width: calc(1920px - var(--sidebar-width));
    }
  }

  @media (max-width: 1920px) {
    > .sidebar-wrapper {
      width: var(--sidebar-width);
    }

    > .contents-wrapper {
      width: calc(100% - var(--sidebar-width));
    }
  }

  @media (max-width: 991px) {
    > .contents-wrapper {
      width: 100%;
    }
  }
}
</style>
