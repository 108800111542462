const darkThemeMeta = { headerTheme: 'dark' }

const routes = [
  {
    name: 'ViewLanding',
    path: '/',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-landing' */ '@/components/views/landing/ViewLanding'),
  },
  {
    name: 'ViewPricePlans',
    path: '/price-plans',
    meta: {
      description:
        "Discover VMOD 3D Library's flexible pricing plans for our 3D digital fabric library service. Choose the plan that best fits your needs and budget.",
      ...darkThemeMeta,
    },
    component: () => import(/* webpackChunkName: 'view-price-plans' */ '@/components/views/price-plans/ViewPricePlans'),
  },
  {
    name: 'ViewTutorial',
    path: '/tutorial',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-tutorial' */ '@/components/views/tutorial/ViewTutorial'),
  },
  {
    name: 'ViewFabricatorLanding',
    path: '/fabricator-generative-ai',
    component: () =>
      import(
        /* webpackChunkName: 'view-fabricator-landing' */ '@/components/views/fabricator-landing/ViewFabricatorLanding'
      ),
    meta: {
      title: 'Meet Fabricator',
      description: "Instantly generate fabric textures with Fabricator - VMOD's fabric generative AI. Start Now.",
      image: 'view-fabricator-landing-og-image.jpg',
      ...darkThemeMeta,
    },
  },
  {
    name: 'ViewAbout',
    path: '/about',
    component: () => import(/* webpackChunkName: 'view-about' */ '@/components/views/about/ViewAbout'),
  },
  {
    name: 'ViewMemberInvitation',
    path: '/member-invitation',
    component: () =>
      import(
        /* webpackChunkName: 'view-member-invitation' */ '@/components/views/group-workspace/ViewMemberInvitation'
      ),
  },
]

const itemRoutes = [
  {
    name: 'ViewTwinFabrics',
    path: '/3d-digital-fabrics',
    meta: darkThemeMeta,
    component: () =>
      import(/* webpackChunkName: 'view-twin-fabrics' */ '@/components/views/item-list/twin-fabrics/ViewTwinFabrics'),
  },
  {
    name: 'ViewTwinFabric',
    path: '/3d-digital-fabrics/:id',
    meta: darkThemeMeta,
    component: () =>
      import(/* webpackChunkName: 'view-twin-fabric' */ '@/components/views/item-list/twin-fabrics/ViewTwinFabric'),
  },
  {
    name: 'ViewDrapeTemplates',
    path: '/3d-digital-fabrics-drape',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-drape-templates' */ '@/components/views/item-list/drape-templates/ViewDrapeTemplates'
      ),
  },
  {
    name: 'ViewDrapeTemplate',
    path: '/3d-digital-fabrics-drape/:id',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-drape-template' */ '@/components/views/item-list/drape-templates/ViewDrapeTemplate'
      ),
  },
  {
    name: 'ViewTextures',
    path: '/3d-digital-fabrics-texture',
    meta: darkThemeMeta,
    component: () =>
      import(/* webpackChunkName: 'view-textures' */ '@/components/views/item-list/textures/ViewTextures'),
  },
  {
    name: 'ViewTexture',
    path: '/3d-digital-fabrics-texture/:id',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-texture' */ '@/components/views/item-list/textures/ViewTexture'),
  },
  { path: '/3d-digital-fabric', redirect: '/3d-digital-fabrics' },
]

const signInAndSignUpRoutes = [
  {
    name: 'ViewSignIn',
    path: '/sign-in',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-sign-in-v2' */ '@/components/views/sign-in/ViewSignIn'),
    children: [
      {
        name: 'ViewSignInSelect',
        path: 'select',
        component: () =>
          import(
            /* webpackChunkName: 'view-sign-in-select' */ '@/components/views/sign-in/sign-in-select/ViewSignInSelect'
          ),
      },
      {
        name: 'ViewInputEmail',
        path: 'input-email',
        component: () => import(/* webpackChunkName: 'view-input-email' */ '@/components/views/sign-in/ViewInputEmail'),
      },
      {
        name: 'ViewSignUp',
        path: 'sign-up',
        component: () => import(/* webpackChunkName: 'view-sign-up' */ '@/components/views/sign-in/ViewSignUp'),
      },
      {
        name: 'ViewInputPassword',
        path: 'input-password',
        component: () =>
          import(/* webpackChunkName: 'view-input-password' */ '@/components/views/sign-in/ViewInputPassword'),
      },
      {
        name: 'ViewSendResetPassword',
        path: 'send-reset-password',
        component: () =>
          import(/* webpackChunkName: 'view-send-reset-password' */ '@/components/views/sign-in/ViewSendResetPassword'),
      },
      {
        name: 'ViewResetPassword',
        path: 'reset-password',
        component: () =>
          import(/* webpackChunkName: 'view-reset-password' */ '@/components/views/sign-in/ViewResetPassword'),
      },
      {
        name: 'ViewSocialSignUp',
        path: 'social-sign-up',
        component: () =>
          import(/* webpackChunkName: 'view-social-sign-up' */ '@/components/views/sign-in/ViewSocialSignUp'),
      },
      {
        name: 'ViewSurvey',
        path: 'survey',
        component: () => import(/* webpackChunkName: 'view-survey' */ '@/components/views/sign-in/survey/ViewSurvey'),
      },
      {
        name: 'ViewEmailVerification',
        path: 'email-verification',
        component: () =>
          import(/* webpackChunkName: 'view-email-verification' */ '@/components/views/sign-in/ViewEmailVerification'),
      },
    ],
  },
  { path: '/reset-password', redirect: '/sign-in/reset-password' },
  { path: '/email-verification', redirect: '/sign-in/email-verification' },
]

const digitizationRoutes = [
  {
    name: 'ViewDigitizationLanding',
    path: '/fabric-digitization',
    meta: {
      title: 'Fabric Digitization Service: Accurate digital twins from real-life fabrics',
      description:
        'VMOD digital fabrics are created by capturing high quality texture and accurate physical parameters of twin real-life fabrics.',
      image: 'digitization-video-01-thumbnail.jpg',
    },
    component: () =>
      import(
        /* webpackChunkName: 'view-digitization-landing' */ '@/components/views/digitization-landing/ViewDigitizationLanding'
      ),
  },
  {
    name: 'ViewCLODigitizationLanding',
    path: '/clo-enterprise',
    meta: {
      title: 'CLO x VMOD Fabric Digitization Service',
      description:
        "Exclusive to Enterprise Clients of CLO. VMOD is CLO's official Fabric Digitization Service partner.",
      image: 'clo-digitization-landing-og-image.jpg',
    },
    component: () =>
      import(
        /* webpackChunkName: 'view-clo-digitization-landing' */ '@/components/views/digitization-landing/ViewCLODigitizationLanding'
      ),
  },
  {
    name: 'ViewDigitizeSwatchOnFabrics',
    path: '/digitize-swatchon-fabrics',
    component: () =>
      import(
        /* webpackChunkName: 'view-digitize-swatchon-fabrics' */ '@/components/views/digitization/ViewDigitizeSwatchOnFabrics'
      ),
  },
  {
    name: 'ViewSharedDraftedDigitizationOrder',
    path: '/drafted-digitization-order/:id',
    component: () =>
      import(
        /* webpackChunkName: 'view-shared-drafted-digitization-order' */ '@/components/views/digitization/ViewSharedDraftedDigitizationOrder'
      ),
  },
  {
    name: 'ViewSharedDigitizationOrder',
    path: '/shared-digitization-order/:id',
    component: () =>
      import(
        /* webpackChunkName: 'view-shared-digitization-order' */ '@/components/views/digitization/ViewSharedDigitizationOrder'
      ),
  },
  {
    path: '/digitization-order-checkout-complete',
    component: () =>
      import(
        /* webpackChunkName: 'view-digitization-order-checkout-complete' */
        '@/components/views/checkout/ViewDigitizationOrderCheckoutComplete'
      ),
  },
  {
    path: '/invoice-checkout-complete',
    component: () =>
      import(
        /* webpackChunkName: 'view-invoice-checkout-complete' */ '@/components/views/checkout/ViewInvoiceCheckoutComplete'
      ),
  },
  {
    name: 'ViewDigitizationOrders',
    path: '/digitization-orders',
    component: () =>
      import(
        /* webpackChunkName: 'view-digitization-orders' */
        '@/components/views/digitization/view-digitization-orders/ViewDigitizationOrders'
      ),
  },
  { path: '/digitization-orders-after-select-workspace', redirect: '/digitization-orders' },
]

const pressAndMediaRoutes = [
  {
    path: '/press-and-media',
    meta: darkThemeMeta,
    component: () =>
      import(/* webpackChunkName: 'view-press-and-media' */ '@/components/views/press-and-media/ViewPressAndMedia'),
    children: [
      {
        path: 'brand',
        component: () =>
          import(
            /* webpackChunkName: 'press-and-media-brand-section' */
            '@/components/views/press-and-media/PressAndMediaBrandSection'
          ),
      },
      {
        path: 'logo',
        component: () =>
          import(
            /* webpackChunkName: 'press-and-media-logo-section' */
            '@/components/views/press-and-media/PressAndMediaLogoSection'
          ),
      },
      {
        path: 'media',
        component: () =>
          import(
            /* webpackChunkName: 'press-and-media-media-section' */
            '@/components/views/press-and-media/PressAndMediaMediaSection'
          ),
      },
      {
        path: 'spelling',
        component: () =>
          import(
            /* webpackChunkName: 'press-and-media-spelling-section' */
            '@/components/views/press-and-media/PressAndMediaSpellingSection'
          ),
      },
      {
        path: 'swatchon',
        component: () =>
          import(
            /* webpackChunkName: 'press-and-media-swatchon-section' */
            '@/components/views/press-and-media/PressAndMediaSwatchOnSection'
          ),
      },
    ],
  },
]

const docsRoutes = [
  {
    name: 'ViewTerms',
    path: '/terms',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-terms' */ '@/components/views/document/ViewTerms'),
  },
]

const legacyRoutes = [
  {
    name: 'ViewAsset',
    path: '/3d-digital-fabric/:assetKey',
    meta: {
      description:
        'Digital Fabric available in SBSAR (Substance 3D), ZFAB (CLO, Marvelous Designer), and PNG for PBR Maps (Base Color, Height, Normal, Roughness, Metallic, Alpha, AO)',
    },
    component: () =>
      import(
        /* webpackChunkName: 'view-digitized-asset' */ '@/components/views/digitization/view-digitized-asset/ViewDigitizedAsset'
      ),
  },
]

const fabricatorAdminRoutes = [
  {
    name: 'ViewFabricatorAdmin',
    path: '/fabricator-admin',
    component: () =>
      import(
        /* webpackChunkName: 'view-fabricator-admin' */
        '@/components/views/fabricator-admin/ViewFabricatorAdmin'
      ),
    children: [
      {
        path: 'login',
        component: () =>
          import(
            /* webpackChunkName: 'fabricator-admin-login' */
            '@/components/views/fabricator-admin/FabricatorAdminLogin'
          ),
      },
      {
        name: 'ViewFabricatorAdminDashboard',
        path: 'dashboard',
        component: () =>
          import(
            /* webpackChunkName: 'fabricator-admin-dashboard' */
            '@/components/views/fabricator-admin/dashboard/FabricatorAdminDashboard'
          ),
      },
      {
        name: 'ViewFabricatorAdminKeywordTagging',
        path: 'keyword-tagging/:targetId',
        component: () =>
          import(
            /* webpackChunkName: 'fabricator-admin-keyword-tagging' */
            '@/components/views/fabricator-admin/keyword-tagging/FabricatorAdminKeywordTagging'
          ),
      },
      {
        path: 'laboratory',
        component: () =>
          import(
            /* webpackChunkName: 'fabricator-admin-laboratory' */
            '@/components/views/fabricator-admin/laboratory/FabricatorAdminLaboratory'
          ),
      },
    ],
  },
]

const cloProcess = [
  {
    path: '/clo-enterprise/sign-in',
    meta: darkThemeMeta,
    component: () => import(/* webpackChunkName: 'view-sign-in' */ '@/components/views/sign-in/ViewSignIn'),
    children: [
      {
        name: 'ViewCLOInputEmail',
        path: 'input-email',
        component: () => import(/* webpackChunkName: 'view-input-email' */ '@/components/views/sign-in/ViewInputEmail'),
      },
      {
        name: 'ViewCLOSignOut',
        path: 'sign-out',
        component: () => import(/* webpackChunkName: 'view-sign-out' */ '@/components/views/sign-in/ViewSignOut'),
      },
    ],
  },
]

const nvidiaOmniverseRoutes = [
  {
    path: '/nvidia-omniverse',
    meta: darkThemeMeta,
    component: () =>
      import(
        /* webpackChunkName: 'view-nvidia-omniverse-landing' */ '@/components/views/nvidia-omniverse/ViewNvidiaOmniverseLanding'
      ),
  },
]

export default [
  ...routes,
  ...itemRoutes,
  ...signInAndSignUpRoutes,
  ...digitizationRoutes,
  ...pressAndMediaRoutes,
  ...docsRoutes,
  ...legacyRoutes,
  ...fabricatorAdminRoutes,
  ...cloProcess,
  ...nvidiaOmniverseRoutes,
]
