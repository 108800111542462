import { store as $store } from '@/store'

const initModal = (options, component) =>
  new Promise((resolve) => {
    // 이미 모달이 띄워져 있는 경우 또 띄우지 않는다.
    if ($store.getters.modals.findIndex((modal) => modal.component === component) !== -1) return

    $store.commit('addModal', { component, options, resolve })
  })

const initModalV2 = (options, component) =>
  new Promise((resolve) => {
    // 이미 모달이 띄워져 있는 경우 또 띄우지 않는다.
    if ($store.getters.modalsV2.findIndex((modal) => modal.component === component) !== -1) return

    $store.commit('addModalV2', { component, options, resolve })
  })

const modal = {
  basic: (options) => initModal(options, 'ModalBasic'),
  alert: (body) => initModal({ titleIcon: 'error', body, buttons: [{ buttonText: 'OK' }] }, 'ModalBasic'),
  custom: ({ options, component }) => initModal(options, component),
  basicV2: (options) => initModalV2(options, 'ModalBasic'),
  customV2: ({ options, component }) => initModalV2(options, component),
}

const tooltip = {
  show: (options) => $store.commit('addTooltip', options),
  hide: (id) => $store.commit('removeTooltip', id),
  allHide: () => $store.commit('removeAllTooltips'),
}

const showToast = ({ html, duration = 3000, type, position = 'x-center y-top', colorTheme } = {}) => {
  if (!html) return

  $store.commit('setToast', { html, duration, type, position, colorTheme })
}

const toast = {
  custom: (options) => showToast(options),
  success: (options) => showToast({ ...options, type: 'success' }),
  error: (options) => showToast({ ...options, type: 'error' }),
  download: (options) => showToast({ ...options, type: 'download' }),
}

export default {
  modal,
  tooltip,
  toast,
}
