<template>
  <div
    ref="refAppSelect"
    class="app-select"
    :class="{ [className]: true, selected: selectedOption, opened: isOpenedDropdown }"
  >
    <div class="contents-wrapper">
      <span v-if="!selectedOption" class="placeholder">{{ placeholder }}</span>
      <template v-else>
        <span v-if="!isUseCustomSelectedOption" class="selected-option">{{ selectedOption }}</span>
        <slot v-else name="custom-selected-option" />
      </template>
    </div>

    <i class="toggle-icon" :class="isOpenedDropdown ? openedToogleIcon : toogleIcon" />

    <button class="select-click-area" @click="onClickAppSelect()" />
  </div>

  <AppTeleport to="#app">
    <WrapperDropdownOverlay
      v-if="isOpenedDropdown"
      ref="refAppSelectDropdown"
      class="app-select-dropdown"
      :class="dropdownClassName"
      :mountBelow="refAppSelect"
      :isUseResizeClose="isUseResizeClose"
      @close="isOpenedDropdown = false"
    >
      <ul class="no-scrollbar" :style="{ width: `${fixedWidth || refAppSelect.offsetWidth}px` }">
        <template v-if="!isUseCustomDropdown">
          <li
            v-for="option in options"
            :key="option"
            :class="{ selected: isUseSelectedOption && option === selectedOption }"
            @click="onClickOption(option)"
          >
            {{ option }}
          </li>
        </template>
        <slot v-else name="custom-dropdown" />
      </ul>
    </WrapperDropdownOverlay>
  </AppTeleport>
</template>

<script setup>
import { ref } from 'vue'

const emit = defineEmits(['set-selected-option'])

defineProps({
  className: {
    type: String,
    default: '',
  },
  dropdownClassName: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  selectedOption: {
    type: String,
    default: '',
  },
  toogleIcon: {
    type: String,
    default: 'fa fa-caret-down',
  },
  openedToogleIcon: {
    type: String,
    default: 'fa fa-caret-down',
  },
  isUseCustomDropdown: Boolean,
  isUseCustomSelectedOption: Boolean,
  isUseResizeClose: {
    type: Boolean,
    default: true,
  },
  isUseSelectedOption: Boolean,
  fixedWidth: {
    type: Number,
    default: 0,
  },
})

const refAppSelect = ref()
const refAppSelectDropdown = ref()

const isOpenedDropdown = ref(false)

const onClickAppSelect = () => {
  isOpenedDropdown.value = true
}

const onClickOption = (option) => {
  emit('set-selected-option', option)

  closeDropdown()
}

const closeDropdown = () => {
  if (refAppSelectDropdown.value) {
    refAppSelectDropdown.value.slideClose()
  }
}

defineExpose({
  closeDropdown,
})
</script>

<style lang="scss">
.app-select {
  position: relative;
  background-color: var(--white);
  border: 1px solid var(--gs-e0);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  transition: border-color 0.4s, margin-bottom 0.4s;

  .contents-wrapper {
    max-width: calc(100% - 22px);
    overflow: hidden;
    display: flex;
    column-gap: 12px;
    font-weight: 400;
    font-size: 16px;

    .placeholder {
      color: var(--gs-af);
    }

    .selected-option {
      color: var(--gs-21);
    }
  }

  .toggle-icon {
    font-size: 16px;
    color: var(--gs-7a);
  }

  .select-click-area {
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

.app-select-dropdown {
  z-index: 21 !important;

  ul {
    transform: translateY(-1px);
    border: 1px solid var(--gs-e0);
    border-radius: 0px 0px 4px 4px;
    background-color: var(--white);
    max-height: 280px;
    overflow: scroll;
    filter: drop-shadow(0px 4px 8px var(--black-20));

    li {
      padding: 8px 16px;
      font-weight: 400;
      font-size: 14px;
      color: var(--gs-4d);
      cursor: pointer;
    }
  }
}
</style>
