import { createMemoryHistory, createWebHistory, createRouter } from 'vue-router'
import routesNoAuth from './routes-no-auth-async'
import routesAuth from './routes-auth-async'
import ViewNotFound from '@/components/views/ViewNotFound'

const onFail = () => {
  const body = document.querySelector('body')
  body.style.visibility = 'visible'
  body.innerHTML = 'Site has changed. Reload after 5 seconds...'
  setTimeout(() => windiw.location.reload(), 5000)
}

const routes = [
  ...routesNoAuth,
  ...routesAuth,
  {
    name: 'ViewNotFound',
    path: '/not-found',
    component: ViewNotFound,
  },
  {
    name: 'ViewNotFound',
    path: '/:pathMatch(.*)*',
    component: ViewNotFound,
  },
]

routes.forEach((route) => {
  if (typeof route.component === 'function') {
    const importPromise = route.component
    // eslint-disable-next-line no-param-reassign
    route.component = () => {
      try {
        return importPromise()
      } catch (e) {
        if (typeof document === 'undefined') return

        onFail()
      }
    }
  }
})

export const newRouter = () => {
  const r = createRouter({
    history: process.env.VUE_APP_SSR ? createMemoryHistory() : createWebHistory(),
    scrollBehavior: () => ({ top: 0 }),
    routes,
  })

  return r
}

// eslint-disable-next-line import/no-mutable-exports
export let router = {}

export const setRouter = (o) => {
  router = o
}

export default {
  router,
  setRouter,
  newRouter,
}
