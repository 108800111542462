<template>
  <picture class="app-webp-img">
    <source type="image/webp" :srcset="refinedSrc.webpSrc" />
    <img :src="refinedSrc.altSrc" :alt="altDescription" :style="{ objectFit }" />
  </picture>
</template>

<script setup>
import { computed } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  fileName: {
    type: String,
    default: '',
  },
  altFileFormat: {
    type: String,
    default: 'png',
  },
  webpSrc: {
    type: String,
    default: '',
  },
  altSrc: {
    type: String,
    default: '',
  },
  altDescription: {
    type: String,
    default: '',
  },
  objectFit: {
    type: String,
    default: 'cover',
  },
})

const { plugins } = useGlobalHooks()

const refinedSrc = computed(() => {
  const { fileName, altFileFormat, webpSrc, altSrc } = props

  if (!fileName) {
    return { webpSrc, altSrc }
  }

  return {
    webpSrc: plugins.$helpers.withCdn(`${fileName}.webp`, 'images/uploads'),
    altSrc: plugins.$helpers.withCdn(`${fileName}.${altFileFormat}`, 'images/uploads'),
  }
})
</script>

<style lang="scss" scoped>
.app-webp-img {
  display: flex;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
