<template>
  <AppHeaderComponents v-if="!isFabricatorRoute" />

  <div class="app-body" :class="{ 'no-select': dragged }">
    <RouterView v-if="$store.getters.isSSR || prepared" class="router-view-container" :style="routerViewStyle" />
    <AppFooter v-if="!isFabricatorRoute" />
  </div>

  <AppAddons :prepared="prepared" />
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineAsyncComponent, computed, watch } from 'vue'
import useLocalStorage from '@/hooks/local-storage'
import useFabricator from '@/hooks/fabricator/fabricator'
import useGlobalHooks from '@/hooks/global-hooks'
import AppHeaderComponents from '@/components/app/header-components/AppHeaderComponents'

const AppFooter = defineAsyncComponent(() => import('@/components/app/AppFooter'))
const AppAddons = defineAsyncComponent(() => import('@/components/app/addons/AppAddons'))

const { plugins, store, router } = useGlobalHooks()

const prepared = ref(null)

const setIsMobile = () => {
  store.commit('setIsMobile')
}

const onScroll = () => {
  setScrollTop()
}

const setScrollTop = () => {
  const el = document.documentElement || document.scrollingElement
  store.commit('setScrollTop', el.scrollTop)
}

const prepare = async () => {
  document.title = router.currentRoute.value.meta.title || plugins.$helpers.meta.defaults.title

  await store.dispatch('bootstrap')
  prepared.value = true

  if (typeof document !== 'undefined') {
    const body = document.getElementsByTagName('body')[0]
    body.removeAttribute('style')
  }
}

const { checkLocalStorage } = useLocalStorage()

const initEventListener = () => {
  const isSSR = process.env.VUE_APP_SSR

  if (isSSR) return

  window.addEventListener('resize', setIsMobile)
  window.addEventListener('scroll', onScroll, { capture: true })
  window.addEventListener('storage', checkLocalStorage)
}

const checkWindowWidth = async () => {
  setIsMobile()

  if (!store.getters.windowInnerWidth) {
    await plugins.$helpers.sleep(100)
    setIsMobile()
  }
}

const checkSurvey = () => {
  const { me } = store.getters

  if (!me || me.surveySubmitted) return

  if (router.currentRoute.value.name === 'ViewSurvey') return

  router.push('/sign-in/survey')
}

onMounted(async () => {
  await prepare()
  checkSurvey()
  initEventListener()
  checkWindowWidth()
  store.dispatch('setHeaderContentsOptions')
})

onUnmounted(() => {
  window.removeEventListener('resize', setIsMobile)
  window.removeEventListener('scroll', onScroll)
  window.removeEventListener('storage', checkLocalStorage)
})

watch(
  () => store.getters.windowInnerWidth,
  () => {
    if (store.getters.windowInnerWidth) return

    checkWindowWidth()
  },
)

watch(
  () => router.currentRoute.value.name,
  async (newRouteName, oldRouteName) => {
    const { me } = store.getters

    if (!me || [newRouteName, oldRouteName].includes('ViewSurvey') || me.surveySubmitted) {
      return
    }

    store.commit('setLoading', { isShow: true })
    await store.dispatch('loadMe')
    store.commit('setLoading', { isShow: false })

    if (!me.surveySubmitted) {
      router.push('/sign-in/survey')
    }
  },
)

const { isFabricatorRoute } = useFabricator()

const dragged = computed(() => store.getters.dragged)

const routerViewStyle = computed(() => {
  const headerHeight = ['var(--app-header-height)']

  const { isShowEmailVerificationBanner } = store.getters.headerContentsOptions

  if (isShowEmailVerificationBanner) {
    headerHeight.push('var(--email-verification-height)')
  }

  return {
    paddingTop: `calc(${headerHeight.join(' + ')})`,
  }
})

watch(
  () => [store.getters.me, router.currentRoute.value],
  () => {
    store.dispatch('setHeaderContentsOptions')
  },
)
</script>

<style lang="scss">
html {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: var(--transparent);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--transparent);
  }
}

#app {
  display: flex;
  flex-direction: column;

  .app-body {
    overflow-x: hidden;

    .router-view-container {
      transition: padding 0.4s;
      min-height: 100vh;
    }
  }
}
</style>
