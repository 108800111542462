const useDigitizationOrders = () => {
  const getDigitizationOrdersInfo = (allDigitizationOrdersInfos, type) =>
    (allDigitizationOrdersInfos || []).find((info) => info.type === type) || {}

  const digitizationOrdersLimit = 10

  return {
    getDigitizationOrdersInfo,
    digitizationOrdersLimit,
  }
}

export default useDigitizationOrders
