/* eslint-disable no-param-reassign */
import { router } from '@/router'
import helpers from '@/helpers'
import useMarketingReferrer from '@/hooks/marketing-referrer'

const app = {
  state: () => ({
    toast: null,
    modals: [],
    modalsV2: [],
    tooltips: [],
    tooltipsV2: [],
    popover: null,
    loading: {
      global: null,
    },
    isMobile: null,
    isTablet: null,
    windowInnerWidth: null,
    windowInnerHeight: null,
    documentInnerHeight: null,
    scrollTops: {}, // scrollTop per page
    scrollTop: null, // global scrollTop value
    lazyLoadedScriptUrls: [],
    headerTheme: 'light', // light | dark
    useTeleport: true,
    sidebarContentsRect: {},
    dragged: false,
    headerContentsOptions: {
      isShowEmailVerificationBanner: false,
    },
  }),
  getters: {
    toast: ({ toast }) => toast,
    modals: ({ modals }) => modals,
    modalsV2: ({ modalsV2 }) => modalsV2,
    tooltips: ({ tooltips }) => tooltips,
    tooltipsV2: ({ tooltipsV2 }) => tooltipsV2,
    popover: ({ popover }) => popover,
    loading: ({ loading }) => loading,
    isMobile: ({ isMobile }) => isMobile,
    isTablet: ({ isTablet }) => isTablet,
    windowInnerWidth: ({ windowInnerWidth }) => windowInnerWidth,
    windowInnerHeight: ({ windowInnerHeight }) => windowInnerHeight,
    documentInnerHeight: ({ documentInnerHeight }) => documentInnerHeight,
    scrollTops: ({ scrollTops }) => scrollTops,
    scrollTop: ({ scrollTop }) => scrollTop,
    lazyLoadedScriptUrls: ({ lazyLoadedScriptUrls }) => lazyLoadedScriptUrls,
    mobileSidebarOpened: ({ mobileSidebarOpened }) => mobileSidebarOpened,
    headerTheme: ({ headerTheme }) => headerTheme,
    useTeleport: ({ useTeleport }) => useTeleport,
    sidebarContentsRect: ({ sidebarContentsRect }) => sidebarContentsRect,
    dragged: ({ dragged }) => dragged,
    headerContentsOptions: ({ headerContentsOptions }) => headerContentsOptions,
  },
  mutations: {
    setToast(state, payload) {
      state.toast = payload
    },
    setIsMobile(state) {
      const { innerWidth, innerHeight } = window || {}

      state.windowInnerWidth = innerWidth || 0
      state.windowInnerHeight = innerHeight || 0
      state.documentInnerHeight = document?.documentElement.clientHeight || 0
      state.isMobile = state.windowInnerWidth < 768
      state.isTablet = state.windowInnerWidth < 992
    },
    setDocumentInnerHeight(state) {
      state.documentInnerHeight = document?.documentElement.clientHeight || 0
    },
    setScrollTops(state, payload) {
      state.scrollTops[payload.routeName] = payload.scrollTop
    },
    setScrollTop(state, scrollTop) {
      state.scrollTop = scrollTop
    },
    setLoading(state, options) {
      state.loading = options
    },
    addModal(state, modal) {
      state.modals.push(modal)
    },
    popModal(state, modal) {
      const idx = state.modals.findIndex((m) => m === modal)
      if (idx >= 0) delete state.modals[idx]

      if (state.modals.filter((m) => m).length === 0) state.modals = []

      state.modals = [...state.modals].filter((e) => e)
    },
    removeAllModals(state) {
      state.modals = []
    },
    addModalV2(state, modal) {
      state.modalsV2.push(modal)
    },
    popModalV2(state, modal) {
      const idx = state.modalsV2.findIndex((m) => m === modal)
      if (idx >= 0) delete state.modalsV2[idx]

      if (state.modalsV2.filter((m) => m).length === 0) state.modalsV2 = []

      state.modalsV2 = [...state.modalsV2].filter((e) => e)
    },
    removeAllModalsV2(state) {
      state.modalsV2 = []
    },
    addTooltip(state, tooltip) {
      state.tooltips.push(tooltip)
    },
    addTooltipV2(state, tooltip) {
      state.tooltipsV2.push(tooltip)
    },
    removeTooltip(state, id) {
      const tooltipIdx = state.tooltips.findIndex((t) => t.id === id)
      if (tooltipIdx >= 0) state.tooltips.splice(tooltipIdx, 1)
    },
    removeTooltipV2(state, id) {
      const tooltipIdx = state.tooltipsV2.findIndex((t) => t.id === id)
      if (tooltipIdx >= 0) state.tooltipsV2.splice(tooltipIdx, 1)
    },
    removeAllTooltips(state) {
      state.tooltips = []
    },
    setPopover(state, popover) {
      state.popover = popover
    },
    addLazyLoadedScriptUrl(state, url) {
      state.lazyLoadedScriptUrls.push(url)
    },
    deleteLazyLoadedScriptUrl(state, url) {
      state.lazyLoadedScriptUrls = state.lazyLoadedScriptUrls.filter(
        (lazyLoadedScriptUrl) => lazyLoadedScriptUrl !== url,
      )
    },
    setHeaderTheme(state, headerTheme) {
      state.headerTheme = headerTheme
    },
    setUseTeleport(state, useTeleport) {
      state.useTeleport = useTeleport
    },
    setSidebarContentsRect(state, rect) {
      state.sidebarContentsRect = rect
    },
    setDragged(state, value) {
      state.dragged = value
    },
    setHeaderContentsOptions(state, options) {
      state.headerContentsOptions = options
    },
  },
  actions: {
    async loadAuthRequired({ getters, dispatch }) {
      if (!getters.header) return

      try {
        await Promise.all([dispatch('loadMe'), dispatch('loadWorkspaces'), dispatch('loadAuthSubscriptionPlans')])

        dispatch('setCurrentWorkspaceIdHeader', getters.me.currentWorkspace.id)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async loadNoAuthRequired({ dispatch }) {
      try {
        await Promise.all([
          dispatch('loadSubscriptionPlans'),
          dispatch('loadReferralCampaigns'),
          dispatch('loadDigitizationOrderOptions'),
          useMarketingReferrer().init(),
        ])
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async bootstrap({ commit, dispatch }, type = 'auth') {
      dispatch('loadNoAuthRequired')
      dispatch('loadLocalIsShowAdminButton')
      commit('setIsMobile')
      commit('setScrollTop', 0)

      if (type === 'auth') {
        try {
          await dispatch('loadAuthToken')
          await dispatch('loadAuthRequired')
        } catch (e) {}
      }
    },
    setHeaderContentsOptions({ commit, getters }) {
      const { me } = getters
      const { name: routeName } = router.currentRoute.value

      const isShowEmailVerificationBanner = (() => {
        if (!me || me.verified) return false

        return [
          'ViewTwinFabrics',
          'ViewTwinFabric',
          'ViewDrapeTemplates',
          'ViewDrapeTemplate',
          'ViewTextures',
          'ViewTexture',
        ].includes(routeName)
      })()

      commit('setHeaderContentsOptions', {
        isShowEmailVerificationBanner,
      })
    },
  },
}

export default app
