/* eslint-disable no-param-reassign */
import crudService from '@/services/crud'

const config = {
  state: () => ({
    countries: null,
  }),
  getters: {
    countries: ({ countries }) => countries,
  },
  actions: {
    async loadCountries({ commit }) {
      try {
        const data = await crudService.country.all()
        commit('setCountries', data)
      } catch (e) {}
    },
  },
  mutations: {
    setCountries(state, countries) {
      state.countries = countries
    },
  },
}

export default config
