import components from './components'
import translations from './translations.json'

const result = {}
const list = [components, translations]
list.forEach((json) =>
  Object.keys(json).forEach((key) => {
    result[key] = json[key]
  })
)

export default result
