<template>
  <div class="app-status-icon" :class="refinedIconInfo.theme">
    <i :class="refinedIconInfo.iconClass" />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  iconClass: {
    type: String,
    default: '',
  },
  theme: {
    type: String,
    default: '',
  },
  iconType: {
    type: String,
    default: '',
  },
})

const refinedIconInfo = computed(() => {
  const { iconClass, theme, iconType } = props

  if (iconType === 'success') {
    return {
      iconClass: 'fa fa-circle-check',
      theme: 'green',
    }
  }

  if (iconType === 'success-v2') {
    return {
      iconClass: 'far fa-circle-check',
      theme: 'green-v2',
    }
  }

  if (iconType === 'download') {
    return {
      iconClass: 'fa fa-arrow-down-to-line',
      theme: 'orange',
    }
  }

  if (iconType === 'error') {
    return {
      iconClass: 'fa fa-circle-exclamation',
      theme: 'red',
    }
  }

  if (iconType === 'error-v2') {
    return {
      iconClass: 'far fa-circle-exclamation',
      theme: 'red-v2',
    }
  }

  if (iconType === 'info') {
    return {
      iconClass: 'far fa-circle-info',
      theme: 'blue',
    }
  }

  return {
    iconClass,
    theme,
  }
})
</script>

<style lang="scss" scoped>
.app-status-icon {
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    font-size: 18px;
  }

  &.green {
    background-color: var(--success-green-20);
    color: var(--success-green);
  }

  &.green-v2 {
    background: var(--status-success-10);
    color: var(--status-success-100);
  }

  &.orange {
    background-color: var(--orange-500-20);
    color: var(--orange-500);
  }

  &.red {
    background-color: var(--warning-red-20);
    color: var(--warning-red);
  }

  &.red-v2 {
    background: var(--status-error-10);
    color: var(--status-error-100);
  }

  &.blue {
    background-color: var(--calm-blue-500-20);
    color: var(--calm-blue-300);
  }

  &.dark.red-v2 {
    background: var(--status-error-10);
    color: var(--status-error-100);
  }

  &.light.red-v2 {
    background: var(--status-error-50);
    color: var(--white);
  }

  &.dark.blue {
    background: var(--cyan-tx-10);
    color: var(--cyan-tx-100);
  }

  @media (max-width: 767px) {
    width: 40px;
    height: 40px;

    i {
      font-size: 14px;
    }
  }
}
</style>
