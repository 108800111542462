const cdn = {
  url: process.env.VUE_APP_CDN_URL,
  withCdn(path, type = 'images/large') {
    if (!path) return

    // 받은 경로를 그대로 사용해야 하는 경우들
    if (
      path.startsWith('http') || // full url
      path.startsWith('data:image') || // blob
      path.startsWith('/') // webpack require
    ) return path

    // 그렇지 않은 경우여도 호환을 위해 반드시 '/'로 시작하도록 맞춘다.
    // eslint-disable-next-line no-param-reassign
    if (!path.includes('/')) path = `/${path}`

    // type: 'images/small', 'images/medium', 'images/large', 'videos/upload'...
    return `${cdn.url}/vfab-${type}${path}`
  },
}

export default cdn
