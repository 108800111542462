<template>
  <div class="app-slide-notice" :style="appSlideNoticeStyle">
    <span ref="refNoticeText" class="notice-text" :class="[noticeType]" v-html="localNoticeText" />
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import useGlobalHooks from '@/hooks/global-hooks'

const props = defineProps({
  noticeText: {
    type: String,
    required: true,
  },
  noticeType: {
    type: String,
    default: 'negative',
  },
  marginTop: {
    type: Number,
    default: 8,
  },
})

const { plugins } = useGlobalHooks()

const isShowNotice = ref(false)
const localNoticeText = ref(' ')

const showNotice = async () => {
  localNoticeText.value = props.noticeText

  await plugins.$helpers.sleep(10)
  isShowNotice.value = true
}

const hideNotice = () => {
  isShowNotice.value = false
}

watch(
  () => props.noticeText,
  (newVal) => {
    if (newVal) {
      showNotice()
      return
    }

    hideNotice()
  },
)

const refNoticeText = ref()
const appSlideNoticeStyle = computed(() => {
  if (!isShowNotice.value) {
    return { height: 0 }
  }

  return {
    height: `${refNoticeText.value.offsetHeight + props.marginTop}px`,
  }
})
</script>

<style lang="scss" scoped>
.app-slide-notice {
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  transition: height 0.4s;

  .notice-text {
    font-weight: 400;
    font-size: 12px;

    b {
      font-weight: 600;
    }

    &.negative {
      color: var(--warning-red);
    }
  }
}
</style>
