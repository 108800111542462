/* eslint-disable no-param-reassign */
const ssr = {
  state: () => ({
    axiosHeader: null,
    frontendError: null,
    metaTags: {},
    structuredData: null,
  }),
  getters: {
    isSSR: () => process.env.VUE_APP_SSR,
    axiosHeader: ({ axiosHeader }) => axiosHeader,
    frontendError: ({ frontendError }) => frontendError,
    metaTags: ({ metaTags }) => metaTags,
    structuredData: ({ structuredData }) => structuredData,
  },
  mutations: {
    setAxiosHeader(state, axiosHeader) {
      state.axiosHeader = axiosHeader
    },
    setFrontendError(state, frontendError) {
      state.frontendError = frontendError
    },
    appendMetaTags(state, tag) {
      state.metaTags[tag.id] = tag
    },
    setStructuredData(state, structuredData) {
      state.structuredData = structuredData
    },
  },
}

export default ssr
