<template>
  <div class="app-status-badge" :class="refinedBadgeInfo.class">
    <span v-html="refinedBadgeInfo.text" />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  badgeText: {
    type: String,
    default: '',
  },
  theme: {
    type: String,
    default: 'black',
    validator: (val) => ['black', 'green', 'blue', 'grey', 'purple', 'red', 'yellow'].includes(val),
  },
  workspaceInfo: {
    type: Object,
    default: () => ({}),
  },
})

const refinedBadgeInfo = computed(() => {
  const { badgeText, theme, workspaceInfo } = props
  const { workspaceType, name, planCategory } = workspaceInfo

  if (!workspaceType || !name) return { class: theme, text: badgeText }

  if (workspaceType === 'personal') return { text: 'PERSONAL WORKSPACE', class: 'workspace-type blue' }

  if (planCategory === 'academic') return { text: 'ACADEMIC WORKSPACE', class: 'workspace-type blue' }

  return { text: 'TEAM WORKSPACE', class: 'workspace-type black' }
})
</script>

<style lang="scss">
.app-status-badge {
  display: block;
  height: fit-content;
  width: fit-content;
  max-width: 560px;
  border: 1px solid;
  backdrop-filter: blur(8px);
  border-radius: 13px;
  padding: 5px 11px;
  background: var(--white-80);
  overflow: hidden;

  span {
    display: flex;
    align-items: center;
    column-gap: 8px;
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.grey {
    color: var(--gs-4d);
    border-color: var(--gs-4d);
  }

  &.black {
    color: var(--gs-21);
    border-color: var(--gs-21);
  }

  &.green {
    color: var(--success-green);
    border-color: var(--success-green);
  }

  &.blue {
    color: var(--calm-blue-400);
    border-color: var(--calm-blue-400);
  }

  &.purple {
    color: var(--purple-fds-100);
    border-color: var(--purple-fds-100);
  }

  &.red {
    color: var(--warning-red);
    border-color: var(--warning-red);
  }

  &.yellow {
    color: var(--orange-500);
    border-color: var(--orange-500);
  }

  &.workspace-type {
    border-radius: 15px;
    padding: 1px 11px;

    span {
      font-weight: 400;
      font-size: 12px;

      b {
        font-weight: 600;
      }
    }

    &.black {
      color: var(--gs-4d);
      border-color: var(--gs-af);
      background-color: var(--black-10);
    }

    &.blue {
      color: var(--calm-blue-500);
      border-color: var(--calm-blue-100);
      background-color: var(--calm-blue-50-20);
    }
  }

  @media (max-width: 767px) {
    padding: 3px 9px;

    span {
      font-size: 12px;
    }

    &.workspace-type {
      padding: 3px 15px;

      span {
        font-size: 12px;
      }
    }
  }
}
</style>
