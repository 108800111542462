import { store as $store } from '@/store'

// DOM을 직접 건드리는 함수들을 이쪽으로 분리
const dom = {
  scrollToTop: () => {
    const el = document.documentElement || document.scrollingElement
    if (el) el.scrollTop = 0
  },
  // eslint-disable-next-line no-shadow
  scrollIntoTop: (dom, offset = 80) => {
    if (!dom) return

    const pos = dom.getBoundingClientRect().top + window.scrollY
    const offsetPos = pos - offset

    const el = document.documentElement || document.scrollingElement
    el.scrollTo({
      top: offsetPos,
      behavior: 'smooth',
    })
  },
  isElementInViewport: (el, percentVisible = 100) => {
    if (!el) return

    const rect = el.getBoundingClientRect()

    return !(
      Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / +-(rect.height / 1)) * 100) < percentVisible ||
      Math.floor(100 - ((rect.bottom - $store.getters.documentInnerHeight) / rect.height) * 100) < percentVisible
    )
  },
  copyToClipboard: (str, link) => {
    const refinedLinkBlob = (() => {
      if (!link) return null

      if (link.startsWith('/')) {
        return new Blob([`<a href="${window.location.origin}${link}">${str}</a>`], { type: 'text/html' })
      }

      return new Blob([`<a href="${link}">${str}</a>`], { type: 'text/html' })
    })()

    if (navigator.clipboard) {
      navigator.clipboard.write([
        new ClipboardItem(
          Object.fromEntries(
            Object.entries({
              'text/plain': new Blob([str], { type: 'text/plain' }),
              'text/html': refinedLinkBlob,
            }).filter((entry) => entry[1]),
          ),
        ),
      ])

      return
    }

    const el = document.createElement('textarea')

    const listener = (e) => {
      e.clipboardData.setData('text/html', link ? `<a href="${link}">${str}</a>` : str)
      e.clipboardData.setData('text/plain', str)
      e.preventDefault()
    }

    if (/Mac|iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      el.value = str
      el.style.fontSize = '16px'
      el.style.position = 'fixed'
      el.style.top = '0'
      el.style.opacity = '0'
      document.body.appendChild(el)

      el.focus()
      el.select()
    }

    document.addEventListener('copy', listener)
    document.execCommand('copy')
    document.removeEventListener('copy', listener)

    el.blur()
    el.remove()
  },
  loadScript: ({ url, attributes }) =>
    new Promise((resolve) => {
      if ($store.getters.lazyLoadedScriptUrls.includes(url)) {
        resolve()
        return
      }

      const scriptTag = document.createElement('script')
      if (attributes) attributes.forEach((attr) => scriptTag.setAttribute(attr.key, attr.value))
      scriptTag.src = url
      scriptTag.async = true
      scriptTag.onload = resolve
      document.head.appendChild(scriptTag)
      $store.commit('addLazyLoadedScriptUrl', url)
    }),
  deleteScript: ({ url }) => {
    const targetNode = document.head.querySelector(`script[src='${url}']`)

    if (targetNode) {
      document.head.removeChild(targetNode)
      $store.commit('deleteLazyLoadedScriptUrl', url)
    }
  },
  // 도큐먼트 자체의 스크롤을 막거나 푼다. (iPhone의 bounce 동작이나 드래깅으로 새로고침 등)
  htmlScroll: {
    lock: () => {
      if ($store.getters.isSSR) return

      const el = document.documentElement || document.scrollingElement
      const scrollPosition = window.pageYOffset

      if (!el) return

      el.style.overflow = 'hidden'
      // el.style.pointerEvents = 'none'
      el.style.position = 'fixed'
      el.style.top = `-${scrollPosition}px`
      el.style.left = '0'
      el.style.right = '0'
    },
    release: () => {
      if ($store.getters.isSSR) return

      const el = document.documentElement || document.scrollingElement

      if (!el) return

      el.removeAttribute('style')
    },
  },
}

export default dom
