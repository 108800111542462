import helpers from '@/helpers'

const useLazyLoads = async () => {
  const loadGA = async () => {
    const h = window.location.host
    if (h.startsWith('localhost') || h.startsWith('127.0.0.1')) return

    helpers.dom.loadScript({ url: '/scripts/gtm.js' })
  }

  await Promise.all([loadGA()])
}

export default useLazyLoads
